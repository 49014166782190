<template>
	<div v-if="feeData" class="m-4 relative overflow-x-auto">
		<table class="text-base w-auto">
			<tr v-for="fee in feeData" class="odd:bg-gray-50 even:bg-white border-t border-b dark:border-gray-700">
				<th scope="row" class="text-left px-6 py-4 font-medium whitespace-nowrap dark:text-white">{{fee.group}}</th>
				<td class="text-right px-5 py-4">{{fee.amount}} €</td>
			</tr>
		</table>
	</div>
   <div class="text-slate-400 dark:text-gray-400">
      <p class="my-4">Bei mehreren Familienmitgliedern zahlt der Antragsteller den vollen Beitrag und alle weiteren Familienmitglieder zahlen den halben Beitrag. Ab dem dritten Kind sind diese beitragsfrei.</p>
      <p class="my-4">*) bei (jährlicher) Vorlage entsprechender Nachweise</p>
   </div>
</template>

<script setup lang="ts">
const feeData = [
	{
		group: 'Erwachsene',
		amount: 120
	},
	{
		group: 'Studenten, Auszubildende, Rentner, Schwerbehinderte *)',
		amount: 60
	},
	{
		group: 'Kinder, Jugendliche',
		amount: 36
	},
]
</script>
