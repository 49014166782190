<template>
	<div>
		<Navbar/>

		<section id="rules" class="relative py-32">

			<div class="container relative">
				<div class="title grid grid-cols-1 mb-8 text-center">
					<h4 class="uppercase text-4xl font-bold tracking-wider mb-3">Schie&szlig; und Platzordnung</h4>
					<div class="border"></div>
				</div>
				<div class="grid grid-cols-1">
					<div class="block">
						<h4 class="font-semibold pb-2">I. Allgemeines</h4>

						<ol class="list-decimal list-outside pb-6">
							<li>Wer gegen die Platz- und Schießordnung verst&ouml;&szlig;t, kann aus dem Verein ausgeschlossen werden.</li>
							<li>Die Platz- und Schie&szlig;ordnung gilt für alle Vereinsmitglieder, deren G&auml;ste und Gastsch&uuml;tzen.</li>
							<li>
								G&auml;ste und Gastsch&uuml;tzen sind im Vorfeld dem Vorstand durch eine E-Mail mit Namen und Datum bekannt zu geben
								(<span class="encrypted-mail" data-name="vorstand" data-domain="bogenschuetzen-muenster" data-tld="de"
								       onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;">
                     </span>). Der Einladende tr&auml;gt f&uuml;r seine G&auml;ste mit Verantwortung.
							</li>
							<li>Das Schie&szlig;en erfordert ein hohes Ma&szlig; an Verantwortung und Disziplin, da ein Fehlverhalten die Gesundheit und das Leben der anderen Sch&uuml;tzen gef&auml;hrdet.</li>
							<li>Das Schie&szlig;en auf lebende Tiere auf dem Vereinsgel&auml;nde f&uuml;hrt zum Vereinsausschluss.</li>
							<li>
								Sch&uuml;ler und Jugendliche unter 18 Jahren d&uuml;rfen auf dem Schie&szlig;platz nur in Begleitung eines &Uuml;bungsleiters, eines ernannten Vertreters oder eines erfahrenen
								Sch&uuml;tzen schie&szlig;en.
							</li>
							<li>
								Sch&uuml;tzen, die über keine ausreichende schie&szlig;technische Erfahrung verf&uuml;gen, d&uuml;rfen nur w&auml;hrend des Trainings oder in Begleitung eines erfahrenen
								Sch&uuml;tzen das Gel&auml;nde nutzen. Zur eigenst&auml;ndigen Nutzung des Platzes ist die Platzreife erforderlich. Die Platzreife wird von Trainer oder von vom Vorstand befugten
								Personen abgenommen.
							</li>
							<li>
								Das Rauchen ist auf dem gesamten Gel&auml;nde verboten, außer an der H&uuml;tte. Kippen sind nicht auf den Boden zu werfen, bitte die daf&uuml;r vorgesehenen Beh&auml;lter
								nutzen.
							</li>
							<li>
								Des Weiteren gilt, jeder nimmt seinen M&uuml;ll/Papier bitte wieder mit nach Hause, das gilt auch für alte Scheibenauflagen oder kaputte Pfeile. Für Fundpfeile gibt es an der
								H&uuml;tte eine Sammelstation, f&uuml;r gefundene kaputte Pfeile ist dort ebenfalls ein Platz, diese werden nicht im Gel&auml;nde liegen gelassen. Vielleicht m&ouml;chte der
								Besitzer seine Spitzen, Inserts, Nocken oder Federn ja weiterverwenden.
							</li>
						</ol>

						<h4 class="font-semibold pb-2">II. Betreten des Gel&auml;ndes</h4>

						<ol class="list-decimal list-outside pb-6">
							<li>
								Das Betreten des eigentlichen Schie&szlig;platzes ist vereinsfremden Personen nur in Begleitung eines Vereinsmitgliedes gestattet. Das Betreten geschieht auf eigene Gefahr,
								der Verein &uuml;bernimmt keine Haftung.
							</li>
							<li>
								Direkt nach der Ankunft am Platz hat sich jeder Sch&uuml;tze in das an der H&uuml;tte ausliegende Schie&szlig;buch einzutragen und beim Verlassen des Platzes wieder auszutragen.
								So weiß jeder, ob sich weitere Personen auf dem Gel&auml;nde befinden. Beim Betreten des Schie&szlig;platzes ist Vorsicht geboten.<br/>
								Die eindeutig erkennbaren Wege und die Laufrichtung sind einzuhalten. Da bei einigen Schie&szlig;st&auml;nden der Gehweg die Schießbahn kreuzt, ist hier für alle besondere
								Vorsicht geboten.
							</li>
						</ol>

						<h4 class="font-semibold pb-2">III. Schie&szlig;en auf dem Gel&auml;nde</h4>

						<ol class="list-decimal list-outside pb-6">
							<li>
								Das Schießen auf dem <strong>Feld Kurs</strong>, der <strong>FITA-Wiese</strong>, dem <strong>3D Parcours</strong> und in der <strong>Sporthalle</strong> erfolgt
								nach den Regeln der Sportordnung des Deutschen Sch&uuml;tzenbundes.
							</li>
							<li>
								Alle Pfeile eines Sch&uuml;tzen sind gem. Sportordnung mit den Initialen oder dem kompletten Namen des Sch&uuml;tzen zu kennzeichnen. Die Kennzeichnung muss dauerhaft auf jedem
								Schaft erfolgen. Eine Kennzeichnung auf den Federn ist nicht ausreichend.
							</li>
							<li>
								Das Schie&szlig;en ist nur auf den fest eingerichteten Schie&szlig;st&auml;nden mit eindeutiger Schussrichtung erlaubt. Die Schussrichtung ist unter allen Umst&auml;nden
								einzuhalten. Beim Schie&szlig;en in Richtung des Hanges und der darauf verlaufenden Stra&szlig;e ist beim Auszug des Pfeils darauf zu achten, dass der Pfeil die H&ouml;he der
								Scheibe oder des 3D Ziels nicht &uuml;bersteigt. Dies betrifft genauso das Schie&szlig;en vom Hang abw&auml;rts. Grunds&auml;tzlich darf der Bogen nur ausgezogen werden, wenn
								der Pfeil bereits auf das Ziel ausgerichtet ist.
							</li>
							<li>
								Beim Schie&szlig;en des <strong>Feldkurses</strong> und des <strong>3D Parcours</strong> sind aus Sicherheitsgr&uuml;nden die vorgeschriebenen Wege unbedingt einzuhalten. Nicht
								gegen die Laufrichtung gehen. Beim Schie&szlig;en auf dem <strong>3D Parcours</strong> gibt es folgende Besonderheiten: Max. 3 Pfeile pro Ziel, geschossen wird nur vom
								Pflock / Abschuss Punkt.
							</li>
							<li>Beim Ziehen der Pfeile ist das 3D-Ziel festzuhalten und auf dem Weg zum Ziel sind die Schu&szlig;fenster zu erhalten.</li>
							<li>Werden Pfeile hinter den 3D Zielen oder Scheiben gesucht, wird der Bogen als Warnhinweis vor das Ziel / die Scheibe gestellt.</li>
							<li>
								Wenn auf der <strong>FITA-Wiese</strong> <strong>Schie&szlig;betrieb</strong>, w&auml;hrend des Trainings herrscht (Trainingszeiten bitte dem Kalender im Mitgliedsbereich
								entnehmen), endet für alle <strong>Feld- und 3D Sch&uuml;tzen</strong> der Kurs am Hochstand. Dies gilt umgekehrt auch für das <strong>Training der Feldbogensch&uuml;tzen</strong>
								(Trainingszeiten bitte dem Kalender im Mitgliedsbereich entnehmen) und der <strong>3D Sch&uuml;tzen</strong> (Trainingszeiten bitte dem Kalender im Mitgliedsbereich entnehmen),
								diese haben an ihren Trainingstagen Vorrang!<br/>
								An den trainingsfreien Tagen erfolgt der Schie&szlig;betrieb in Absprache. Grunds&auml;tzlich darf ein Ziel nur beschossen werden, wenn der Raum hinter dem Ziel frei ist.
							</li>
							<li>Besonders bei den Zielen an der Grenze des Gel&auml;ndes ist auf Menschen und Tiere zu achten, die sich evtl. auf dem angrenzenden Gel&auml;nde befinden.</li>
							<li>Auf der FITA-Wiese ist das Schie&szlig;en nur gemeinsam von der Schie&szlig;linie aus erlaubt.</li>
						</ol>

						<p class="pb-6">
							Der Vorstand<br/>
							August 2024
						</p>

						<p><a href="data/Schieß-und-Platzordnung-2024.pdf" target="_blank" class="font-semibold">Download als PDF</a></p>
					</div>
				</div>
			</div>

		</section>

		<section class="pt-6 bg-dark text-white">
			<Info/>
		</section>

		<Footer/>

		<ScrollToTop/>
	</div>
</template>

<script setup>
import {onMounted} from "vue";

import {useLoaderState} from "@/store/isloading";

import Navbar from "@/components/layout/navbar.vue";
import Info from "@/components/layout/info.vue";
import Footer from "@/components/layout/footer.vue";
import ScrollToTop from "@/components/scroll-to-top.vue";

const isLoading = useLoaderState();
const { changeStateTrue } = isLoading;

onMounted(() => {
	changeStateTrue();

	setTimeout(() => {
		const { changeStateFalse } = isLoading;
		changeStateFalse();
	}, 1000);
})
</script>
