<template>
   <div class="container relative">
      <div class="title grid grid-cols-1 pb-6 text-center" data-aos="fade-down" data-aos-duration="500">
         <h1 class="font-semibold text-4xl mb-3">Termine</h1>
         <div class="border"></div>
      </div>

      <div class="grid grid-cols-1 pb-6">
         <ul>
            <li v-for="(event, index) in events" :key="event.id" class="event py-2" data-aos="fade-up" data-aos-duration="500" :data-aos-delay="index * 200">
               <div class="grid grid-cols-4 lg:grid-cols-9 w-80%">
                  <div class="row-span-4 lg:row-span-2 lg:text-4xl text-4xl font-semibold text-center content-center lg:px-4 mr-5 border-r-2">
                     {{ formatDay(event.start) }}
                  </div>
                  <div class="col-span-3 lg:col-span-8 py-2">
                     <h4 class="text-xl font-semibold">{{ event.name }}</h4>
                  </div>
                  <div class="col-span-3 lg:col-span-2 pb-2">
                     <FontAwesomeIcon icon="fa-solid fa-calendar" class="pr-3" />
                     {{formatDate(event.start)}}
                  </div>
                  <div class="col-span-3 lg:col-span-2 pb-2" v-if="formatTime(event.start)">
                     <FontAwesomeIcon icon="fa-solid fa-clock" class="pr-3" />
                     {{formatTime(event.start) }}
                  </div>
                  <div class="col-span-3 lg:col-span-4 pb-2">
                     <FontAwesomeIcon icon="fa-solid fa-location-dot" class="pr-3" />
                     {{ event.locationName }}
                  </div>
               </div>
            </li>
            <li class="px-8 py-4">
               <router-link to="/events" class="text-lg text-black hover:text-yellow-500">weitere Termine</router-link>
            </li>
         </ul>
      </div>
   </div>
</template>

<script setup lang="ts">
import {ref} from "vue";
import { DateTime } from 'luxon';

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const events = ref('');

const getEvents = () => {
   fetch('api/calendar.php', {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      }
   })
         .then(response => response.json())
         .then(data => events.value = data)
         .catch(error => console.log(error));
}

getEvents();

function formatDay(dateString: string): string {
   return DateTime.fromISO(dateString)
         .setLocale('de')
         .toFormat('ccc');
}

function formatDate(dateString: string): string {
   return DateTime.fromISO(dateString)
         .setLocale('de')
         .toFormat('dd.MMMM.yyyy');
}

function formatTime(dateString: string): string {
   const time = DateTime.fromISO(dateString)
         .setLocale('de')
         .toFormat('HH:mm');
   if (time == '00:00') {
      return 't.b.a.';
   }
   return time.concat(' Uhr');
}
</script>
