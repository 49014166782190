import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "container relative" }
const _hoisted_2 = { class: "grid lg:grid-cols-4 md:grid-cols-2 grid-cols-2 mt-6 gap-6" }
const _hoisted_3 = ["data-aos-delay"]
const _hoisted_4 = { class: "member-photo relative overflow-hidden bg-white bg-clip-border" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "mask" }
const _hoisted_7 = { class: "icon" }
const _hoisted_8 = ["data-name"]
const _hoisted_9 = { class: "p-6" }
const _hoisted_10 = { class: "block text-lg font-semibold antialiased leading-relaxed" }
const _hoisted_11 = { class: "block text-base antialiased leading-relaxed" }
const _hoisted_12 = ["innerHTML"]

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import team from "@/assets/json/team.json";

export default /*@__PURE__*/_defineComponent({
  __name: 'team',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", {
      class: "title grid grid-cols-1 pb-6 text-center",
      "data-aos": "fade-down",
      "data-aos-duration": "500"
    }, [
      _createElementVNode("h1", { class: "font-semibold text-4xl mb-3" }, "Vorstand"),
      _createElementVNode("div", { class: "border" })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(team), (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "team-member relative flex flex-col bg-white border bg-clip-border m-4",
          "data-aos": "fade-up",
          "data-aos-duration": "500",
          "data-aos-delay": index * 200
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              src: item.picture,
              alt: "card-image",
              class: "object-cover"
            }, null, 8, _hoisted_5),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("a", {
                  href: "#",
                  "data-name": item.email,
                  "data-domain": "bogenschuetzen-muenster",
                  "data-tld": "de",
                  onclick: "window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"
                }, [
                  _createVNode(_unref(FontAwesomeIcon), { icon: "fa-regular fa-envelope" })
                ], 8, _hoisted_8)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("h5", _hoisted_10, _toDisplayString(item.name), 1),
            _createElementVNode("p", _hoisted_11, _toDisplayString(item.title), 1),
            _createElementVNode("p", {
              class: "block text-base antialiased leading-normal mt-2",
              innerHTML: item.description
            }, null, 8, _hoisted_12)
          ])
        ], 8, _hoisted_3))
      }), 128))
    ])
  ]))
}
}

})