	<template>
	<div>
		<Navbar />

		<section id="statute" class="relative py-32">

			<div class="container relative">
				<div class="title grid grid-cols-1 mb-8 text-center">
					<h4 class="text-4xl font-bold mb-3">Satzung</h4>
					<div class="border"></div>
				</div>
				<div class="grid grid-cols-1">
					<div class="block">

						<h4 class="font-semibold pb-2">Präambel</h4>

						<p class="pb-6">
							Aus Gründen der leichteren Lesbarkeit wird in dieser Satzung die gewohnte männliche
							Sprachform bei personenbezogenen Substantiven und Pronomen verwendet. Dies impliziert
							jedoch keine Benachteiligung anderer Geschlechter, sondern soll im Sinne der sprachlichen
							Vereinfachung als geschlechtsneutral zu verstehen sein.
						</p>

						<h4 class="font-semibold text-center pb-4">§ 1: NAME, SITZ UND FARBEN</h4>

						<ol class="list-decimal list-outside pb-6">
							<li>Der Verein führt den Namen Bogenschützen Münster e.V., von 1979 und hat seinen Sitz in Münster.</li>
							<li>Seine Farben sind blau – weiß – rot – schwarz.</li>
							<li>Er ist in das Vereinsregister in Münster unter der Nr. 2342 eingetragen.</li>
						</ol>

						<h4 class="font-semibold text-center pb-4">§ 2: ZWECK UND ZIELE DES VEREINS</h4>

						<ol class="list-decimal list-outside pb-6">
							<li>Der Verein verfolgt ausschließlich und unmittelbar gemeinnützige Zwecke im Sinne des Abschnitts „Steuerbegünstigte Zwecke“ der Abgabenordnung. Der Verein ist selbstlos tätig;
								er verfolgt nicht in erster Linie eigenwirtschaftliche Zwecke.</li>
							<li>Der Verein ist politisch und konfessionell neutral. Er tritt rassistischen, fremden- und verfassungsfeindlichen Bestrebungen,
								sowie menschenverachtenden oder diskriminierendenVerhaltensweisen aktiv entgegen.</li>
							<li>Zweck des Vereins ist die Förderung des Bogensports. Der Satzungszweck wird insbesondere verwirklicht durch:
								<ol>
									<li>Pflege und Ausübung des Bogenschießens in allen Sparten auf sportlicher Grundlage,</li>
									<li>der Abhaltung von Veranstaltungen schießsportlicher Art,</li>
									<li> sowie der Förderung der körperlichen und seelischen Gesundheit seiner Mitglieder, insbesondere der Jugend.</li>
								</ol>
							</li>
							<li>Mittel des Vereins dürfen nur für die satzungsgemäßen Zwecke verwendet werden. Die Mitglieder erhalten keine Zuwendungen aus Mitteln des Vereins.</li>
							<li>Es darf keine Person durch Ausgaben, die dem Zweck des Vereins fremd sind oder durch unverhältnismäßig hohe Vergütungen begünstigt werden.</li>
						</ol>

						<h4 class="font-semibold text-center pb-4">§ 3: MITGLIEDSCHAFTEN DES VEREINS</h4>

						<ol class="list-decimal list-outside pb-6">
							<li>Der Verein ist Mitglied
								<ul>
									<li>im Westfälischen Schützenbund e.V. (WSB),</li>
									<li>im Deutschen Schützenbund e. V. (DSB),</li>
									<li>im Stadtsportbund Münster e. V. (SSB MS),</li>
									<li>im Landessportbund Nordrhein-Westfalen e. V. (LSB NRW) und</li>
									<li>im Bogensportverband Nordrhein-Westfalen (BVNW).</li>
								</ul>
							</li>
							<li>Der Verein erkennt die Satzungen Ordnungen und Wettkampfbestimmungen der Bünde und Verbände nach Absatz 1 als verbindlich an.</li>
							<li>Um die Durchführung der Vereinsaufgaben zu ermöglichen, kann der Gesamtvorstand den Eintritt in Bünde, Verbände und Organisationen und über den Austritt
								beschließen.</li>
						</ol>

						<h4 class="font-semibold text-center pb-4">§ 4: GESCHÄFTSJAHR</h4>

						<p class="pb-6">Das Geschäftsjahr ist das Kalenderjahr.</p>

						<h4 class="font-semibold text-center pb-4">§ 5:ERWERB DER MITGLIEDSCHAFT</h4>

						<ol class="list-decimal list-outside pb-6">
							<li>Die Mitgliedschaft wird durch Aufnahme erworben. Zur Aufnahme ist eine Anmeldung in Textform (auch digital) an den Verein erforderlich. Mitglied können alle natürlichen Personen werden.
								Die Aufnahme in den Verein ist davon abhängig, dass sich das Mitglied für die Dauer der Mitgliedschaft verpflichtet, am SEPA-Lastschriftverfahren teilzunehmen. Über die endgültige Aufnahme
								entscheidet der Vorstand. Mit Abgabe des Aufnahmeantrages erkennt das zukünftige Mitglied die Satzung des Vereins und die Ordnungen in der jeweils gültigen Fassung an. Die Mitgliedschaft
								beginnt mit dem Tag der Annahme desAufnahmeantrages.</li>
							<li>Ein Aufnahmeanspruch besteht nicht. Die Ablehnung der Aufnahme muss nicht begründet werden.</li>
							<li>Ein vereinsinternes Rechtsmittel gegen die Ablehnung der Aufnahme besteht nicht. Der Weg zu den ordentlichen Gerichten bleibt unberührt.</li>
							<li>Der Aufnahmeantrag eines Minderjährigen bedarf der Einwilligung der gesetzlichen Vertreter in Textform (auch digital).</li>
						</ol>

						<h4 class="font-semibold text-center pb-4">§ 6: ARTEN DER MITGLIEDSCHAFT</h4>

						<ol class="list-decimal list-outside pb-6">
							<li>Der Verein besteht aus:
								<ol>
									<li>aktiven Mitgliedern,</li>
									<li>passiven Mitgliedern,</li>
									<li>Ehrenmitgliedern.</li>
								</ol>
							</li>
							<li>Aktive Mitglieder sind Mitglieder, die Angebote des Vereins im Rahmen der bestehenden Ordnungen nutzen können und/oder am Spiel- bzw. Wettkampfbetrieb teilnehmen können.</li>
							<li>Für passive Mitglieder steht die Förderung des Vereins oder bestimmter Vereinsabteilungen im Vordergrund. Sie nutzen die sportlichen Angebote des Vereins nicht.</li>
							<li>Ehrenmitglieder werden auf Vorschlag des Vorstandes per Beschluss mit einfacher Mehrheit der Jahreshauptversammlung ernannt. Ihnen steht ein Stimmrecht in der Jahreshauptversammlung zu.</li>
						</ol>

						<h4 class="font-semibold text-center pb-4">§ 7: RECHTE UND PFLICHTEN DER MITGLIEDER</h4>

						<ol class="list-decimal list-outside pb-6">
							<li>Jedes Mitglied ist verpflichtet, den Verein nach besten Kräften zu unterstützen, die festgesetzten Beiträge unaufgefordert zu leisten, die Regelungen dieser Satzung sowie der
								Vereinsordnungen zu beachten, einzuhalten und insbesondere den Anweisungen und Entscheidungen der Vereinsorgane und von diesen autorisierten Personen Folge zu leisten.</li>
							<li>Stimmberechtigt sind alle Mitglieder, die den Jahresbeitrag für das Kalenderjahr entrichtet haben, das dem Jahr der Hauptversammlung vorausgegangen ist. Jugendliche sind ab
								Vollendung des 14. Lebensjahres stimmberechtigt und üben ihre Mitgliedschaftsrechte im Verein persönlich aus. Ihre gesetzlichen Vertreter sind von der Wahrnehmung der Mit gliedschaftsrechte
								ausgeschlossen, sind aber berechtigt, an Jahreshauptversammlungen teilzunehmen.</li>
						</ol>

						<h4 class="font-semibold text-center pb-4">§ 8: ENDE DER MITGLIEDSCHAFT</h4>

						<ol class="list-decimal list-outside pb-6">
							<li> Die Mitgliedschaft endet durch Austritt aus dem Verein (Kündigung); durch Ausschluss aus dem Verein; durch Streichung aus der Mitgliederliste; durch Tod. Der Tod bewirkt das sofortige
								Ausscheiden eines Mitglieds.</li>
							<li>Der Austritt aus dem Verein (Kündigung) erfolgt durch Erklärung in Textform (auch digi tal) an die Geschäftsadresse des Vereins durch das Mitglied oder seine gesetzlichen Vertreter. Die Kündigungsfrist
								beträgt drei Monate zum Kalenderjahresende. Der Austritt ist mit Ablauf des Kalenderjahres rechtskräftig.</li>
							<li>Bei Beendigung der Mitgliedschaft, gleich aus welchem Grund, erlöschen alle Rechte aus dem Mitgliedschaftsverhältnis. Noch ausstehende Verpflichtungen aus dem Mitglied schaftsverhältnis, insbesondere
								ausstehende Beitragspflichten, bleiben hiervon unberührt. Vereinseigene Gegenstände sind dem Verein herauszugeben oder wertmäßig abzugelten. Dem austretenden Mitglied steht kein Anspruch auf Rückzahlung
								überzahlter Beiträge zu.</li>
						</ol>

						<h4 class="font-semibold text-center pb-4">§ 9: AUSSCHLUSS AUS DEM VEREIN, STREICHUNG VON DER MITGLIEDERLISTE</h4>

						<ol class="list-decimal list-outside pb-6">
							<li>Ein Ausschluss kann erfolgen, wenn ein Mitglied
								<ul>
									<li>grob gegen die Satzung oder Ordnungen schuldhaft verstößt;</li>
									<li>in grober Weise den Interessen des Vereins und seiner Ziele zuwiderhandelt;</li>
									<li>sich grob unsportlich verhält;</li>
									<li>dem Verein oder dem Ansehen des Vereins durch unehrenhaftes Verhalten, insbesondere durch Äußerung extremistischer oder verfassungsfeindlicher Gesinnung bzw. Haltung innerhalb oder außerhalb des
										Vereins oder durch die Mitgliedschaft in einer extremistischen Partei oder Organisation schadet;</li>
									<li>gegen die Grundsätze des Kinder- und Jugendschutzes verstößt.</li>
								</ul>
							</li>
							<li>Über den Ausschluss entscheidet der Vorstand auf Antrag. Zur Antragstellung ist jedes Mitglied berechtigt.</li>
							<li>Der Antrag auf Ausschluss ist dem betroffenen Mitglied samt Begründung zuzuleiten. Das betroffene Mitglied wird aufgefordert, innerhalb einer Frist von drei Wochen zu dem Antrag auf Ausschluss Stellung
								zu nehmen. Nach Ablauf der Frist ist vom Vorstand unter Berücksichtigung der Stellungnahme des betroffenen Mitglieds über den Antrag mit einfacher Mehrheit zu entscheiden.</li>
							<li>Der Beschluss ist dem Mitglied in Textform (auch digital) mit Gründen mitzuteilen. Der Ausschließungsbeschluss wird mit Bekanntgabe an das betroffene Mitglied wirksam.</li>
							<li>Dem betroffenen Mitglied steht gegen den Ausschluss kein vereinsinternes Beschwerderecht zu. Der Weg zu den ordentlichen Gerichten bleibt unberührt.</li>
							<li>Ein Mitglied kann durch Beschluss des Vorstandes von der Mitgliederliste gestrichen werden, wenn es trotz Mahnung in Textform (auch digital) mit der Zahlung von Zahlungsverpflichtungen in Verzug ist. Der
								Beschluss über die Streichung darf durch den Vorstand erst dann gefasst werden, wenn nach Versendung der Mahnung drei Wochen verstrichen sind und dem Mitglied in der Mahnung die Streichung bei Nichtzahlung
								angekündigt worden ist. Der Beschluss über die Streichung ist dem betroffenen Mitglied in Textform (auch digital) mitzuteilen.</li>
							<li>Handelt es sich bei dem auszuschließenden oder zu streichenden Mitglied um ein Mitglied des Vorstandes, dann entscheidet die Jahreshauptversammlung.</li>
						</ol>

						<h4 class="font-semibold text-center pb-4">§ 10: BEITRÄGE DER MITGLIEDER</h4>

						<ol class="list-decimal list-outside pb-6">
							<li> Die Mitglieder sind verpflichtet Beiträge in Form von Jahresbeiträgen zu zahlen. Für unterschiedliche Mitgliedergruppen können unterschiedliche Beiträge festgesetzt werden.</li>
							<li>Über Höhe und Fälligkeit sämtlicher Beiträge entscheidet der Vorstand durch Beschluss. Beschlüsse über Beitragsfestsetzungen sind den Mitgliedern bekannt zu geben.</li>
							<li>Mitgliedsbeiträge sind Jahresbeiträge für das Kalenderjahr und im Voraus zu entrichten. Der Mitgliedsbeitrag wird im Januar eines jeden Jahres im Voraus fällig und wird grundsätzlich durch
								Lastschrifteinzugsverfahren im ersten Quartal des Jahres erhoben. Neue Vereinsmitglieder zahlen ab dem nächsten Monatsersten anteilig den Jahresbeitrag.</li>
							<li>Sämtliche Einnahmen des Vereins sind zur Erfüllung der Vereinsziele zu verwenden.</li>
							<li>Das Mitglied ist verpflichtet, dem Verein Änderungen des Namens, der Bankverbindung, der Anschrift, der Telefon-Nummer sowie der E-Mail-Adresse mitzuteilen.</li>
							<li>Von Mitgliedern, die dem Verein ein SEPA-Lastschriftmandat erteilt haben, werden die Beiträge zum Fälligkeitstermin eingezogen.</li>
							<li>Kann der Bankeinzug aus Gründen, die das Mitglied zu vertreten hat, nicht erfolgen, sind dadurch entstehende Bankgebühren durch das Mitglied zu tragen.</li>
							<li>Wenn der Beitrag im Zeitpunkt der Fälligkeit nicht beim Verein eingegangen ist, befindet sich das Mitglied ohne weitere Mahnung in Zahlungsverzug. Der ausstehende Beitrag kann dann bis zu
								seinem Eingang gemäß § 288 Abs. 1 BGB mit 5 Prozentpunkten über dem Basiszinssatz nach § 247 BGB verzinst werden.</li>
							<li>Fällige Forderungen können vom Verein außergerichtlich und gerichtlich geltend gemacht werden. Die entstehenden Kosten hat das Mitglied zu tragen.</li>
							<li>Der Vorstand kann in begründeten Einzelfällen Beitragsleistungen oder -pflichten ganz oder teilweise erlassen oder stunden bzw. Mitgliedern die Teilnahme am SEPA-Lastschriftverfahren erlassen.</li>
						</ol>

						<h4 class="font-semibold text-center pb-4">§ 11: LEITUNG UND VERWALTUNG</h4>

						<ol class="list-decimal list-outside pb-6">
							<li>Der Vorstand besteht aus dem ersten und dem zweiten Vorsitzenden, der 2. Vorsitzende ist außerdem Sozialwart, dem Kassenwart, dem Schriftführer, dem Jugendwart, dem Sportwart, dem 3D-Wart
								und dem IT-Wart.</li>
							<li>Der 1. Vorsitzende und der 2. Vorsitzende leiten die Vereinsgeschäfte und vertreten ihn in Einzelvertretung gerichtlich und außergerichtlich. Der 2. Vorsitzende darf sein Vorstands amt
								im Innenverhältnis nur im nichteintragungspflichtigen Verhinderungsfall ausüben.</li>
							<li>Vorstandsmitglieder können nur Mitglieder des Vereins werden. Abwesende Mitglieder können gewählt werden, wenn sie ihre Bereitschaft zur Wahl und Annahme des Amtes vorher schriftlich
								erklärt haben und die schriftliche Erklärung in der Jahreshauptversammlung vorliegt. Der Vorstand wird von der Jahreshauptversammlung auf zwei Jahre gewählt. Der Vorstand bleibt bis
								zu einer Neuwahl bestehen. Eine Wiederwahl ist möglich.</li>
							<li>Der Vorstand unterstützt den Vorsitzenden in der Leitung des Vereins. Ihm obliegt es, die Veranstaltungen des Vereins festzulegen. Die Erledigung bestimmter Angelegenheiten kann delegiert
								werden. Er entscheidet in allen in der Satzung vorgesehenen Fällen. Die Vorstandssitzungen werden geleitet vom 1. Vorsitzenden; im Falle seiner Verhinderung vom 2. Vorsitzenden. Über
								die Sitzungen und Beschlüsse wird vom Schriftführer Protokoll geführt.</li>
							<li>Fällt ein Mitglied des Vorstandes längerfristig aus, so ist der Vorstand berechtigt, eine Ersatzperson zu wählen, die an die Stelle des ausgefallenen bis zur nächsten Hauptversammlung
								tritt. Diese Bestimmung findet auf den Vorsitzenden des Vereins keine Anwendung. Fällt der 2. Vorsitzende aus, so wird er bis zur nächsten Hauptversammlung durch den Kassenwart vertreten.</li>
							<li>Der Vorstand ist berechtigt, für die Organisation des Vereins notwendige Ordnungen, wie z. B. eine Geschäftsordnung des Vorstands, eine Beitragsordnung, eine Platzordnung etc. zu erlassen.
								Diese Ordnungen sind nicht Bestandteil der Satzung. Die Ordnungen dürfen der Satzung nicht widersprechen. Im Zweifelsfall gelten die Regelungen dieser Satzung.</li>
						</ol>

						<h4 class="font-semibold text-center pb-4">§ 12: KASSENPRÜFUNG</h4>

						<p class="pb-6">Die Hauptversammlung wählt für die Dauer von zwei Jahren zwei Kassenprüfer. Sie haben nach eigenem freiem Ermessen das Rechnungswesen des Vereins zu prüfen und über das Ergebnis in der Hauptversammlung zu berichten.</p>

						<h4 class="font-semibold text-center pb-4">§ 13: EHRENAMTLICHE TÄTIGKEIT</h4>

						<ol class="list-decimal list-outside pb-6">
							<li>Sämtliche Mitglieder des Vereins üben ihre Tätigkeit ehrenamtlich aus. An kein Vereins mitglied darf ein Gewinnanteil, Zuwendungen, unverhältnismäßig hohe Vergünstigungen oder Ähnliches bezahlt werden.</li>
							<li>Der Vorstand kann Vereinsmitgliedern für deren besonders geleisteten Zeit- und Arbeitsaufwand angemessene Entschädigungen zukommen lassen. Über das Ob einer solchen Entschädigung und die entsprechende
								Höhe entscheidet der Vorstand in einer Vorstandssitzung.</li>
							<li>Während der Dauer der Vorstandstätigkeit wird den Mitgliedern des Vorstands als angemessene Entschädigung für ihre ehrenamtliche Vorstandstätigkeit die Zahlung des Mitgliedsbeitrags erlassen. Das Stimmrecht
								in der JHV bleibt erhalten.</li>
						</ol>

						<h4 class="font-semibold text-center pb-4">§ 14: HAFTUNG</h4>

						<ol class="list-decimal list-outside pb-6">
							<li>Ehrenamtlich Tätige und Organ- oder Amtsträger, deren Vergütung den Ehrenamtsfreibetrag gem. § 3 Nr. 26 a EStG im Jahr nicht übersteigt, haften für Schäden gegenüber den Mitgliedern und gegenüber dem Verein,
								die sie in Erfüllung ihrer ehrenamtlichen Tätigkeit verursachen, nur für Vorsatz und grobe Fahrlässigkeit.</li>
							<li>Der Verein haftet gegenüber den Mitgliedern im Innenverhältnis nicht für leicht fahrlässig verursachte Schäden, die Mitglieder bei der Ausübung des Sports, bei Benutzung von An lagen oder Einrichtungen
								des Vereins oder bei Vereinsveranstaltungen erleiden, soweit solche Schäden nicht durch Versicherungen des Vereins abgedeckt sind.</li>
						</ol>

						<h4 class="font-semibold text-center pb-4">§ 15: JAHRESHAUPTVERSAMMLUNG</h4>

						<ol class="list-decimal list-outside pb-6">
							<li>Oberstes Organ des Vereins ist die Jahreshauptversammlung.</li>
							<li>Eine Jahreshauptversammlung findet mindestens einmal im Kalenderjahr statt.</li>
							<li>Die Jahreshauptversammlung wird vom Vorstand unter Einhaltung einer Frist von zwei Wochen in Textform (auch digital) unter Angabe der Tagesordnung einberufen. Die Frist beginnt mit dem auf die Absendung der
								Einladung folgenden Tag. Die Tagesordnung setzt der Vorstand durch Beschluss fest. Es sind alle Mitglieder zur Teilnahme einzuladen.</li>
							<li>Der Vorstand kann jederzeit eine Jahreshauptversammlung einberufen, wenn das Interesse des Vereins es erfordert. Sie muss einberufen werden, wenn es von mindestens 10 % aller Mitglieder in Textform (auch
								digital) unter Angabe des Zwecks und der Gründe vom Vorstand verlangt wird. Gegenstand der Beschlussfassung einer derartigen Jahreshauptversammlung sind nur die mit der Einberufung mitgeteilten
								Tagesordnungspunkte. Ergänzungen der Tagesordnung sowie weitere Anträge sind ausgeschlossen. Einberufungsform und -frist ergeben sich aus Absatz 3.</li>
							<li>Jede ordnungsgemäß einberufene Jahreshauptversammlung ist unabhängig von der Anzahl der anwesenden Mitglieder beschlussfähig.</li>
							<li>Die Jahreshauptversammlung wird von dem Vorsitzenden, bei dessen Verhinderung von einem anderen Mitglied des Vorstandes geleitet. Ist kein Mitglied des Vorstandes anwesend, bestimmt die Versammlung den
								Versammlungsleiter. Der Versammlungsleiter bestimmt den Protokollführer. Der Versammlungsleiter kann die Leitung der Versammlung auf eine andere Person übertragen.</li>
							<li>Alle Abstimmungen und Wahlen erfolgen grundsätzlich offen per Handzeichen oder bei Teilnahme an einer virtuellen oder hybriden Mitgliederversammlung auch durch elektronische Stimmabgabe. Wenn geheime
								Abstimmung beantragt wird, entscheidet darüber die Jahreshauptversammlung. Eine geheime Abstimmung ist durchzuführen, wenn dies von mindestens einem Fünftel der abgegebenen gültigen Stimmen verlangt wird.</li>
							<li>Die Beschlüsse der Jahreshauptversammlung werden mit einfacher Mehrheit der abgegebenen gültigen Stimmen gefasst, soweit in der Satzung nicht etwas anderes bestimmt ist. Bei Stimmengleichheit gilt ein
								Antrag als abgelehnt. Stimmenthaltungen werden als ungültige Stimmen gewertet und nicht mitgezählt.</li>
							<li>Über die Beschlüsse der Jahreshauptversammlung ist ein Protokoll aufzunehmen, das vondem Versammlungsleiter und von dem Protokollführer zu unterzeichnen ist.</li>
							<li>Jedes Mitglied hat mit Vollendung des 14. Lebensjahres in der Mitgliederversammlung ein Stimmrecht und eine Stimme. Wählbar ist jedes Mitglied mit Vollendung des 18. Lebensjahres. Das Stimmrecht kann nur
								persönlich ausgeübt werden und ist nicht übertragbar.</li>
							<li> Die Mitglieder des Vorstands werden einzeln gewählt. Es ist der Kandidat gewählt, der mehr als die Hälfte der abgegebenen gültigen Stimmen erhalten hat. Erreicht kein Kandidat im 1. Wahlgang die absolute
								Mehrheit, findet eine Stichwahl zwischen den Kandidaten mit der höchsten und der zweithöchsten Stimmenzahl statt. Gewählt ist im 2. Wahlgang der Kandidat, der die meisten Stimmen erhält. Die Vorstandsmitglieder
								sind wirksam gewählt, wenn die gewählten Kandidaten das Amt angenommen haben.</li>
							<li>Anträge zur Tagesordnung können von allen Mitgliedern in Textform (auch digital) unter Angabe des Namens gestellt werden. Die Anträge sind zu begründen und müssen dem Vorstand spätestens eine Woche vor der
								Jahreshauptversammlung zugehen.</li>
							<li>Jahreshauptversammlungen finden grundsätzlich als Präsenzversammlungen statt. Der Vorstand kann beschließen, dass die Jahreshauptversammlung ausschließlich als virtuelle Jahreshauptversammlung in Form einer
								onlinebasierten Videoversammlung oder als Kombination von Präsenzversammlung und virtueller Versammlung (hybride Jahreshauptversammlung) stattfindet. Ohne einen entsprechenden Beschluss des Vorstandes
								haben die Mitglieder keinen Anspruch darauf, virtuell an einer Präsenzversammlung teilzunehmen.</li>
							<li>Teilnahme- und stimmberechtigten Personen, die online an der virtuellen bzw. an der hybriden Jahreshauptversammlung teilnehmen, wird durch geeignete technische Vorrichtungen die Möglichkeit gegeben,
								virtuell an der Jahreshauptversammlung teilzunehmen und das Stimmrecht auf elektronischem Wege auszuüben. Die Einzelheiten zur Registrierung und Gewährleistung der Zugangsberechtigung und Ausübung des
								Stimmrechts können in der Geschäftsordnung geregelt werden. Die Auswahl der technischen Rahmenbedingungen (z. B. die Auswahl der zu verwendenden Software bzw. Programme) legt der Vorstand per Beschluss fest.</li>
							<li>Technische Widrigkeiten, die zu einer Beeinträchtigung bei der Teilnahme oder bei der Stimmrechtsausübung führen, berechtigen die teilnahme- und stimmberechtigten Personen nicht dazu, gefasste Beschlüsse und
								vorgenommene Wahlen anzufechten, es sei denn, die Ursache der technischen Widrigkeiten ist dem Verantwortungsbereich des Vereins zuzurechnen.</li>
							<li> Im Übrigen gelten für die virtuelle bzw. hybride Jahreshauptversammlung die Vorschriften über die Jahreshauptversammlung sinngemäß.</li>
						</ol>

						<h4 class="font-semibold text-center pb-4">§ 16: ZUSTÄNDIGKEIT DER JAHRESHAUPTVERSAMMLUNG</h4>

						<p class="pb-6">Die Jahreshauptversammlung ist für folgende Vereinsangelegenheiten zuständig:</p>

						<ol class="list-decimal list-outside pb-6">
							<li>Entgegennahme der Berichte des Vorstandes;</li>
							<li>Entgegennahme der Haushaltsplanung durch den Vorstand;</li>
							<li> Entgegennahme des Kassenprüfberichtes;</li>
							<li>Entlastung des Vorstandes;</li>
							<li>Wahl und Abberufung der Mitglieder des Vorstandes, soweit die Satzung nicht etwas Abweichendes regelt;</li>
							<li>Wahl der Kassenprüfer;</li>
							<li>Änderung der Satzung und Beschlussfassung über Auflösung oder Fusion des Vereins;</li>
							<li>Beschlussfassung über eingegangene Anträge (gem. § 11 Abs. 12).</li>
						</ol>

						<h4 class="font-semibold text-center pb-4">§ 17: BESCHLUSSFASSUNG</h4>

						<p class="pb-6">Zur Beschlussfassung über folgende Punkte ist die Mehrheit von dreiviertel der in der Hauptversammlung abgegebenen gültigen Stimmen erforderlich:</p>

						<ol class="list-decimal list-outside pb-6">
							<li>Änderung der Satzung.<br>
								Wird eine Satzungsbestimmung, welche die Voraussetzung der Anerkennung der Gemeinnützigkeit berührt, geändert, neu eingeführt oder aufgehoben, so ist das zuständige Finanzamt zu benachrichtigen.</li>
							<li>Auflösung bzw. Verschmelzung des Vereins.<br>
								Die Auflösung bzw. Verschmelzung des Vereins kann nur auf einer Hauptversammlung beschlossen werden, auf deren Tagesordnung eine Beschlussfassung hierüber angekündigt ist.</li>
						</ol>

						<h4 class="font-semibold text-center pb-4">§ 18: VEREINSVERMÖGEN</h4>

						<p class="pb-6">Bei Auflösung des Vereins oder bei Wegfall steuerbegünstigter Zwecke fällt das Vermögen des Vereins an eine juristische Person des öffentlichen Rechts oder eine andere steuerbegünstigte Körperschaft
							zwecks Verwendung für Förderung des Sports.</p>

						<h4 class="font-semibold text-center pb-4">§ 19: SCHLUSSBESTIMMUNG</h4>

						<ol class="list-decimal list-outside pb-6">
							<li>Diese Satzung wurde durch die Mitgliederversammlung am 21.04.2023 beschlossen.</li>
							<li>Diese Satzung tritt mit Eintragung in das Vereinsregister in Kraft.</li>
							<li>Sie ist unter Angabe des Eintragungsdatums den Vereinsmitgliedern mitzuteilen (z. B. per E-Mail, Newsletter, Veröffentlichung auf der Vereinshomepage etc.), damit gilt die alte Satzung als aufgehoben.</li>
						</ol>

						<p><a href="data/Satzung2024.pdf" target="_blank" class="font-semibold">Download als PDF</a></p>

					</div>
				</div>
			</div>

		</section>

		<section class="pt-6 bg-dark text-white">
			<Info/>
		</section>

		<Footer/>

		<ScrollToTop/>
	</div>
</template>

<script setup>
import {onMounted} from "vue";

import {useLoaderState} from "@/store/isloading";

import Navbar from "@/components/layout/navbar.vue";
import Info from "@/components/layout/info.vue";
import Footer from "@/components/layout/footer.vue";
import ScrollToTop from "@/components/scroll-to-top.vue";

const isLoading = useLoaderState();
const {changeStateTrue} = isLoading;

onMounted(() => {
	changeStateTrue();

	setTimeout(() => {
		const {changeStateFalse} = isLoading;
		changeStateFalse();
	}, 1000);
})
</script>
