<template>
	<div class="container relative">
		<div class="title grid grid-cols-1 pb-6 text-center" data-aos="fade-down" data-aos-duration="500">
			<h1 class="font-semibold text-4xl mb-3">Unser Verein</h1>
			<div class="border"></div>
		</div>

		<div class="grid grid-cols-1 mt-6 relative" data-aos="fade-up" data-aos-duration="500">
			<div class="block text-base">
				<p class="my-4">Unseren Verein gibt es seit 1979 und hat sich der Ausrichtung des Bogensports verschrieben. Bei uns könnt ihr WA-, Feldbogen und auch 3D-Bogenschießen praktizieren. Geschossen werden
					kann bei uns jede Bogenart.
					Vom englischen Langbogen über den olympischen Bogen, Jagd- oder Blankbogen und dem Compoundbogen.</p>
				<p class="my-4">Unser Außengelände im Norden von Münster an der Werse gelegen bietet eine große Schießwiese auf der alle Standardentfernungen trainiert werden können. Außerdem einen Feldbogen- und
					3D-Parcours. Das Gelände kann das ganze
					Jahr über genutzt werden. Im Winterhalbjahr haben wir zusätzliche Trainingszeiten in einer Sporthalle in Münster.</p>
				<p class="my-4">Neben den ausgezeichneten Trainingsmöglichkeiten bietet der Grillplatz einen Raum für gemütliches Beisammensein. Bei gutem Wetter sollte auch der Picknickkorb nicht fehlen, speziell,
					wenn man mal mit Freunden da ist, die
					nicht die ganze Zeit den Bogen in der Hand halten wollen.</p>
				<p class="my-4">In regelmäßigen Abständen richten wir Turniere und Meisterschaften aus. Im Frühjahr unsere Vereinsmeisterschaften (WA, Feld- und 3D), im Sommer unser traditionelles Nachtschießen und im
					Herbst das legendäre
					KillDings-Turnier. Die wettkampforientierten Mitglieder nehmen an Verbandsturnieren (wie der Landes- und der Deutschen Meisterschaft) oder an freien Turnieren teil.</p>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
</script>
