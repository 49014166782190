import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'

import Index from '@/pages/index.vue'
import Journey from "@/pages/journey.vue";
import Events from "@/pages/events.vue";
import Downloads from "@/pages/downloads.vue";
import Rules from "@/pages/rules.vue";
import Statute from "@/pages/statute.vue";
import Impressum from "@/pages/impressum.vue";
import PrivacyPolicy from "@/pages/privacy-policy.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    component: Index
  },
  {
    path: '/journey',
    name: 'journey',
    component: Journey
  },
  {
    path: '/events',
    name: 'events',
    component: Events
  },
  {
    path: '/downloads',
    name: 'downloads',
    component: Downloads
  },
  {
    path: '/rules',
    name: 'rules',
    component: Rules
  },
  {
    path: '/statute',
    name: 'statute',
    component: Statute
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: Impressum
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    else {
      return { top: 0, behavior: 'smooth' }
    }
  }
})

export default router
