<template>
   <div class="block">

      <p class="pb-3">April bis Oktober, draußen</p>
      <p class="pb-2">Gelände „Werseesch“</p>

      <ul class="list-disc list-inside monospace">
         <li>Di 17:30 Uhr Feldbogentraining</li>
         <li>Sa 10:00 – 12:00 Uhr Jugendtraining</li>
         <li>Sa 12;00 – 14:00 Uhr Training</li>
         <li>So 12:00 – 16:00 Uhr Freies Training im 3D-Parcours (ganzjährig)</li>
      </ul>
   </div>

   <div class="mt-5">
      <h4 class="pb-3">Oktober bis März, Sporthalle</h4>
      <p class="pb-2">Kleine Sporthalle der Erna-de-Vries-Schule, Spichernstr. 17-19</p>

      <ul class="list-disc list-inside monospace">
         <li>Do 20:30 – 22:30 Uhr Training</li>
         <li>Fr 20:00 – 22:00 Uhr Training</li>
         <li>Sa 10:00 – 12:00 Uhr Jugendtraining</li>
         <li>Sa 12:00 – 14:00 Uhr Training</li>
      </ul>
   </div>
</template>
