<template>
	<Navbar/>

	<section id="journey" class="relative py-32">

		<div class="container relative">
			<div class="title grid grid-cols-1 mb-8 text-center">
				<h4 class="text-4xl font-bold mb-3">Anfahrt</h4>
				<div class="border"></div>
			</div>

			<div class="grid grid-cols-1">
				<div class="block">

					<p>Wenn Du Interesse bekommen hast und mehr sehen und wissen willst, dann schau einfach mal bei uns vorbei. Unser Freigelände am Werseesch in Gelmer ist gut zu erreichen.</p>

					<div class="mt-5">
						<p><b>Mit dem Bus:</b></p>

						<p>Die Linie 4 fährt von Münster aus nach Gelmer, Haltestelle Schornheide. Der Fußweg zu unserem Gelände ist im folgenden Kartenausschnitt blau eingezeichnet.</p>

						<img :src="journey" alt="Fussweg zum Gelände" class="w-full my-4"/>

						<p>Busfahrpl&auml;ne nach Gelmer und zur&uuml;ck nach M&uuml;nster findest du <a href="https://www.stadtwerke-muenster.de/unterwegs/fahrplan" target="_blank" class="font-semibold">hier</a>.</p>
					</div>

					<div class="mt-5">
						<p><b>Mit dem Auto</b> von der A1 Abfahrt Münster-Ost/Telgte oder von Münster über den Schiffahrter Damm. Für Navigationssysteme und Routenplaner u.U. ist die nächstgelegene Adresse "Werseesch 70", da das
							Gelände keine
							eigene
							wirkliche Adresse besitzt.</p>

						<div id="map-outdoor" class="map my-4">
							<l-map ref="map-outdoor" :zoom="15" :center="[52.02881207722906, 7.682168483734132]" :useGlobalLeaflet="false" :options="options">
								<l-tile-layer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" :attribution="attribution"></l-tile-layer>
								<l-marker :lat-lng="[52.02881207722906, 7.682168483734132]"></l-marker>
							</l-map>
						</div>
					</div>

					<div class="mt-5">
						<p><b>In den Wintermonaten</b> findest Du uns zu unseren Trainingszeiten in der kleinen Sporthalle der Erna-de-Vries-Schule (früher Karl-Wagenfeld-Schule) in der Spichernstraße 17-19.</p>

						<div id="map-indoor" class="map my-4">
							<l-map ref="map-indoor" :zoom="15" :center="[51.93508432941878, 7.624104022979737]" :useGlobalLeaflet="false" :options="options">
								<l-tile-layer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" :attribution="attribution"></l-tile-layer>
								<l-marker :lat-lng="[51.93508432941878, 7.624104022979737]"></l-marker>
							</l-map>
						</div>
					</div>

				</div>
			</div>

		</div>

	</section>

	<section class="pt-6 bg-dark text-white">
		<Info/>
	</section>

	<Footer/>

	<ScrollToTop/>
</template>

<script setup lang="ts">
import {onMounted} from "vue";

import {useLoaderState} from "@/store/isloading";

import "leaflet/dist/leaflet.css";
import {LMap, LMarker, LTileLayer} from "@vue-leaflet/vue-leaflet";

import Navbar from "@/components/layout/navbar.vue";
import Info from "@/components/layout/info.vue";
import Footer from "@/components/layout/footer.vue";
import ScrollToTop from "@/components/scroll-to-top.vue";

import journey from "@/assets/images/journey/footpath.png";

const attribution = "&copy; <a href=\"http://www.openstreetmap.org/copyright\">OpenStreetMap</a>";

const options = {
	"scrollWheelZoom": false,
	"dragging": false,
}

const isLoading = useLoaderState();
const { changeStateTrue } = isLoading;

onMounted(() => {
	changeStateTrue();

	setTimeout(() => {
		const { changeStateFalse } = isLoading;
		changeStateFalse();
	}, 1000);
})
</script>

<style scoped>
.map {
	height: 500px;
	margin-bottom: 1.2rem;
	z-index: 5;
}
</style>
