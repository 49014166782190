<template>
   <div v-if="isLoading.state">
      <loader name="circular" loadingText="" textColor="#ffffff" textSize="15" textWeight="800" object="#fbba00" color1="#e31e24" color2="#2b2a29" size="5" speed="2" bg="#ffffff" objectbg="#999793" opacity="100"
              :disableScrolling="true"></loader>
   </div>

   <router-view/>
</template>

<script setup>
import {onMounted} from "vue";
import {useLoaderState} from "@/store/isloading";
import loader from "vue3-ui-preloader";
import "vue3-ui-preloader/dist/loader.css"

const isLoading = useLoaderState();
const { changeStateTrue } = isLoading;

onMounted(() => {
   changeStateTrue();
   setTimeout(() => {
      const { changeStateFalse } = isLoading;
      changeStateFalse();
   }, 500);
})
</script>
