import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "journey",
  class: "relative py-32"
}
const _hoisted_2 = { class: "container relative" }
const _hoisted_3 = { class: "grid grid-cols-1" }
const _hoisted_4 = { class: "block" }
const _hoisted_5 = { class: "mt-5" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "mt-5" }
const _hoisted_8 = {
  id: "map-outdoor",
  class: "map my-4"
}
const _hoisted_9 = { class: "mt-5" }
const _hoisted_10 = {
  id: "map-indoor",
  class: "map my-4"
}
const _hoisted_11 = { class: "pt-6 bg-dark text-white" }

import {onMounted} from "vue";

import {useLoaderState} from "@/store/isloading";

import "leaflet/dist/leaflet.css";
import {LMap, LMarker, LTileLayer} from "@vue-leaflet/vue-leaflet";

import Navbar from "@/components/layout/navbar.vue";
import Info from "@/components/layout/info.vue";
import Footer from "@/components/layout/footer.vue";
import ScrollToTop from "@/components/scroll-to-top.vue";

import journey from "@/assets/images/journey/footpath.png";

const attribution = "&copy; <a href=\"http://www.openstreetmap.org/copyright\">OpenStreetMap</a>";


export default /*@__PURE__*/_defineComponent({
  __name: 'journey',
  setup(__props) {

const options = {
	"scrollWheelZoom": false,
	"dragging": false,
}

const isLoading = useLoaderState();
const { changeStateTrue } = isLoading;

onMounted(() => {
	changeStateTrue();

	setTimeout(() => {
		const { changeStateFalse } = isLoading;
		changeStateFalse();
	}, 1000);
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(Navbar),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "title grid grid-cols-1 mb-8 text-center" }, [
          _createElementVNode("h4", { class: "text-4xl font-bold mb-3" }, "Anfahrt"),
          _createElementVNode("div", { class: "border" })
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[5] || (_cache[5] = _createElementVNode("p", null, "Wenn Du Interesse bekommen hast und mehr sehen und wissen willst, dann schau einfach mal bei uns vorbei. Unser Freigelände am Werseesch in Gelmer ist gut zu erreichen.", -1)),
            _createElementVNode("div", _hoisted_5, [
              _cache[0] || (_cache[0] = _createElementVNode("p", null, [
                _createElementVNode("b", null, "Mit dem Bus:")
              ], -1)),
              _cache[1] || (_cache[1] = _createElementVNode("p", null, "Die Linie 4 fährt von Münster aus nach Gelmer, Haltestelle Schornheide. Der Fußweg zu unserem Gelände ist im folgenden Kartenausschnitt blau eingezeichnet.", -1)),
              _createElementVNode("img", {
                src: _unref(journey),
                alt: "Fussweg zum Gelände",
                class: "w-full my-4"
              }, null, 8, _hoisted_6),
              _cache[2] || (_cache[2] = _createElementVNode("p", null, [
                _createTextVNode("Busfahrpläne nach Gelmer und zurück nach Münster findest du "),
                _createElementVNode("a", {
                  href: "https://www.stadtwerke-muenster.de/unterwegs/fahrplan",
                  target: "_blank",
                  class: "font-semibold"
                }, "hier"),
                _createTextVNode(".")
              ], -1))
            ]),
            _createElementVNode("div", _hoisted_7, [
              _cache[3] || (_cache[3] = _createElementVNode("p", null, [
                _createElementVNode("b", null, "Mit dem Auto"),
                _createTextVNode(" von der A1 Abfahrt Münster-Ost/Telgte oder von Münster über den Schiffahrter Damm. Für Navigationssysteme und Routenplaner u.U. ist die nächstgelegene Adresse \"Werseesch 70\", da das Gelände keine eigene wirkliche Adresse besitzt.")
              ], -1)),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_unref(LMap), {
                  ref: "map-outdoor",
                  zoom: 15,
                  center: [52.02881207722906, 7.682168483734132],
                  useGlobalLeaflet: false,
                  options: options
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(LTileLayer), {
                      url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
                      attribution: attribution
                    }),
                    _createVNode(_unref(LMarker), { "lat-lng": [52.02881207722906, 7.682168483734132] })
                  ]),
                  _: 1
                }, 512)
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[4] || (_cache[4] = _createElementVNode("p", null, [
                _createElementVNode("b", null, "In den Wintermonaten"),
                _createTextVNode(" findest Du uns zu unseren Trainingszeiten in der kleinen Sporthalle der Erna-de-Vries-Schule (früher Karl-Wagenfeld-Schule) in der Spichernstraße 17-19.")
              ], -1)),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_unref(LMap), {
                  ref: "map-indoor",
                  zoom: 15,
                  center: [51.93508432941878, 7.624104022979737],
                  useGlobalLeaflet: false,
                  options: options
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(LTileLayer), {
                      url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
                      attribution: attribution
                    }),
                    _createVNode(_unref(LMarker), { "lat-lng": [51.93508432941878, 7.624104022979737] })
                  ]),
                  _: 1
                }, 512)
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_11, [
      _createVNode(Info)
    ]),
    _createVNode(Footer),
    _createVNode(ScrollToTop)
  ], 64))
}
}

})