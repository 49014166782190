<template>
	<div class="top-hero">
		<VueTinySlider :options="options">
			<img :src="image" alt="" v-for="image in images">
		</VueTinySlider>
		<div class="container">
			<div class="grid grid-cols-1 text-center mt-5 mb-10">
				<div class="my-6">
					<h4 class="lg:leading-normal leading-normal text-4xl lg:text-[42px]">Willkommen bei den Bogenschützen Münster von 1979 e.V.</h4>
				</div>
				<div class="my-3">
					<p class="dark:text-white/60 text-xl mx-auto my-4">
						Auf den folgenden Seiten findet Ihr Informationen über den Bogensport, unseren Verein, aktuelle Ereignisse und Ansprechpartner des Vereins.
					</p>
					<p class="dark:text-white/60 text-xl mx-auto my-4">
						Wir bieten euch in unserem Verein die Möglichkeit den Bogensport als Hobby, Freizeitgestaltung und natürlich auch Wettkampfsport auszuüben. Geschossen werden können bei uns
						alle Bogenarten in den Disziplinen WA, Feldbogen und 3D.
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import {ref} from 'vue';
import VueTinySlider from "@mentorkadriu/vue-tiny-slider/src/components/VueTinySlider.vue";

import banner1 from '@/assets/images/banner/bsm-logo-1.jpg'
import banner2 from '@/assets/images/banner/bsm-logo-2.jpg'
import banner3 from '@/assets/images/banner/bsm-logo-3.jpg'
import banner4 from '@/assets/images/banner/bsm-logo-4.jpg'
import banner5 from '@/assets/images/banner/bsm-logo-5.jpg'
import banner6 from '@/assets/images/banner/bsm-logo-6.jpg'

const images = ref([
	banner1, banner2, banner3, banner4, banner5, banner6
])

const options = {
	items: 1,
	autoplay: true,
	autoplayTimeout: 7500,
	autoplayButtonOutput: false,
	controls: false,
	nav: false,
	speed: 2000
}
</script>
