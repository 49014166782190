import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "top-hero" }
const _hoisted_2 = ["src"]

import {ref} from 'vue';
import VueTinySlider from "@mentorkadriu/vue-tiny-slider/src/components/VueTinySlider.vue";

import banner1 from '@/assets/images/banner/bsm-logo-1.jpg'
import banner2 from '@/assets/images/banner/bsm-logo-2.jpg'
import banner3 from '@/assets/images/banner/bsm-logo-3.jpg'
import banner4 from '@/assets/images/banner/bsm-logo-4.jpg'
import banner5 from '@/assets/images/banner/bsm-logo-5.jpg'
import banner6 from '@/assets/images/banner/bsm-logo-6.jpg'


export default /*@__PURE__*/_defineComponent({
  __name: 'hero',
  setup(__props) {

const images = ref([
	banner1, banner2, banner3, banner4, banner5, banner6
])

const options = {
	items: 1,
	autoplay: true,
	autoplayTimeout: 7500,
	autoplayButtonOutput: false,
	controls: false,
	nav: false,
	speed: 2000
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(VueTinySlider, { options: options }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(images.value, (image) => {
          return (_openBlock(), _createElementBlock("img", {
            src: image,
            alt: ""
          }, null, 8, _hoisted_2))
        }), 256))
      ]),
      _: 1
    }),
    _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"container\"><div class=\"grid grid-cols-1 text-center mt-5 mb-10\"><div class=\"my-6\"><h4 class=\"lg:leading-normal leading-normal text-4xl lg:text-[42px]\">Willkommen bei den Bogenschützen Münster von 1979 e.V.</h4></div><div class=\"my-3\"><p class=\"dark:text-white/60 text-xl mx-auto my-4\"> Auf den folgenden Seiten findet Ihr Informationen über den Bogensport, unseren Verein, aktuelle Ereignisse und Ansprechpartner des Vereins. </p><p class=\"dark:text-white/60 text-xl mx-auto my-4\"> Wir bieten euch in unserem Verein die Möglichkeit den Bogensport als Hobby, Freizeitgestaltung und natürlich auch Wettkampfsport auszuüben. Geschossen werden können bei uns alle Bogenarten in den Disziplinen WA, Feldbogen und 3D. </p></div></div></div>", 1))
  ]))
}
}

})