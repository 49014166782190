<template>
	<Navbar/>

	<section id="privacy-policy" class="relative py-32">

		<div class="container relative">

			<div class="title grid grid-cols-1 mb-8 text-center">
				<h4 class="text-4xl font-bold mb-3">Datenschutzerkl&auml;rung</h4>
				<div class="border"></div>
			</div>

			<div class="grid grid-cols-1">
				<div class="block">

					<p class="pb-4">Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im Rahmen der Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsfähigen und nutzerfreundlichen Internetauftritts,
						inklusive seiner Inhalte und der dort angebotenen Leistungen, verarbeitet.</p>

					<p class="pb-4">Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung (nachfolgend nur „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführter
						Vorgang oder
						jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die
						Verwendung,
						die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.</p>

					<p class="pb-4">Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit wir entweder allein oder gemeinsam mit
						anderen
						über die Zwecke und Mittel der Verarbeitung entscheiden. Zudem informieren wir Sie nachfolgend über die von uns zu Optimierungszwecken sowie zur Steigerung der Nutzungsqualität eingesetzten Fremdkomponenten, soweit
						hierdurch
						Dritte Daten in wiederum eigener Verantwortung verarbeiten.
					</p>

					<p class="pb-4">Unsere Datenschutzerklärung ist wie folgt gegliedert:</p>

					<p class="pb-4">
						I. Informationen über uns als Verantwortliche<br/>
						II. Rechte der Nutzer und Betroffenen <br/>
						III. Informationen zur Datenverarbeitung
					</p>

					<h4 class="font-semibold">I. Informationen über uns als Verantwortliche</h4>

					<p class="pb-4">Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:</p>

					<p class="pb-4">
						Bogenschützen Münster e.V. von 1979<br/>
						Werseesch<br/>
						48157 Münster
					</p>

					<p class="pb-4">
						Telefon: 0177 856 24 54<br/>
						E-Mail: <span class="cryptedmail" data-name="info" data-domain="bogenschutzen-muenster" data-tld="de"
						              onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"></span><br/>
					</p>

					<h4 class="font-semibold pb-2">II. Rechte der Nutzer und Betroffenen</h4>

					<p class="pb-4">Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung haben die Nutzer und Betroffenen das Recht</p>

					<ul class="list-disc pb-4">
						<li>auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über die verarbeiteten Daten, auf weitere Informationen über die Datenverarbeitung sowie auf Kopien der Daten (vgl. auch Art. 15
							DSGVO);
						</li>
						<li>auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger Daten (vgl. auch Art. 16 DSGVO);</li>
						<li>auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), oder, alternativ, soweit eine weitere Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich ist, auf Einschränkung der
							Verarbeitung nach
							Maßgabe von Art. 18 DSGVO;
						</li>
						<li>auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf Übermittlung dieser Daten an andere Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);</li>
						<li>auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass die sie betreffenden Daten durch den Anbieter unter Verstoß gegen datenschutzrechtliche Bestimmungen verarbeitet werden (vgl.
							auch Art.
							77 DSGVO).
						</li>
					</ul>

					<p class="pb-4">Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen gegenüber Daten durch den Anbieter offengelegt worden sind, über jedwede Berichtigung oder Löschung von Daten oder die Einschränkung der
						Verarbeitung,
						die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt, zu unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese Mitteilung unmöglich oder mit einem unverhältnismäßigen Aufwand verbunden ist.
						Unbeschadet
						dessen hat der Nutzer ein Recht auf Auskunft über diese Empfänger.</p>


					<p class="pb-4"><b>Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf Widerspruch gegen die künftige Verarbeitung der sie betreffenden Daten, sofern die Daten durch den Anbieter nach Maßgabe von Art. 6
						Abs. 1 lit. f) DSGVO verarbeitet werden. Insbesondere ist ein Widerspruch gegen die Datenverarbeitung zum Zwecke der Direktwerbung statthaft.</b></p>

					<h4 class="font-semibold pb-2">III. Informationen zur Datenverarbeitung</h4>

					<p class="pb-4">Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt, der Löschung der Daten keine gesetzlichen Aufbewahrungspflichten
						entgegenstehen und
						nachfolgend keine anderslautenden Angaben zu einzelnen Verarbeitungsverfahren gemacht werden.</p>

					<h5 class="font-semibold pb-2">Serverdaten</h5>

					<p class="pb-4">Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren und stabilen Internetauftritts, werden Daten durch Ihren Internet-Browser an uns bzw. an unseren Webspace-Provider übermittelt. Mit diesen
						sog. Server-Logfiles werden u.a. Typ und Version Ihres Internetbrowsers, das Betriebssystem, die Website, von der aus Sie auf unseren Internetauftritt gewechselt haben (Referrer URL), die Website(s) unseres
						Internetauftritts,
						die Sie besuchen, Datum und Uhrzeit des jeweiligen Zugriffs sowie die IP-Adresse des Internetanschlusses, von dem aus die Nutzung unseres Internetauftritts erfolgt, erhoben.</p>

					<p class="pb-4">Diese so erhobenen Daten werden vorübergehend gespeichert, dies jedoch nicht gemeinsam mit anderen Daten von Ihnen.</p>

					<p class="pb-4">Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Verbesserung, Stabilität, Funktionalität und Sicherheit unseres Internetauftritts.</p>

					<p class="pb-4">Die Daten werden spätestens nach sieben Tage wieder gelöscht, soweit keine weitere Aufbewahrung zu Beweiszwecken erforderlich ist. Andernfalls sind die Daten bis zur endgültigen Klärung eines Vorfalls ganz oder
						teilweise
						von der Löschung ausgenommen.</p>

					<h5 class="font-semibold pb-2">Cookies</h5>

					<p class="pb-4"><b>A) SITZUNGS-COOKIES/SESSION-COOKIES</b></p>

					<p class="pb-4">Wir verwenden mit unserem Internetauftritt sog. Cookies. Cookies sind kleine Textdateien oder andere Speichertechnologien, die durch den von Ihnen eingesetzten Internet-Browser auf Ihrem Endgerät ablegt und
						gespeichert werden.
						Durch diese Cookies werden im individuellen Umfang bestimmte Informationen von Ihnen, wie beispielsweise Ihre Browser- oder Standortdaten oder Ihre IP-Adresse, verarbeitet.</p>

					<p class="pb-4">Durch diese Verarbeitung wird unser Internetauftritt benutzerfreundlicher, effektiver und sicherer, da die Verarbeitung bspw. die Wiedergabe unseres Internetauftritts in unterschiedlichen Sprachen oder das Angebot
						einer
						Warenkorbfunktion ermöglicht.</p>

					<p class="pb-4">Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit b.) DSGVO, sofern diese Cookies Daten zur Vertragsanbahnung oder Vertragsabwicklung verarbeitet werden.</p>

					<p class="pb-4">Falls die Verarbeitung nicht der Vertragsanbahnung oder Vertragsabwicklung dient, liegt unser berechtigtes Interesse in der Verbesserung der Funktionalität unseres Internetauftritts. Rechtsgrundlage ist in dann
						Art. 6
						Abs. 1 lit. f) DSGVO.</p>

					<p class="pb-4">Mit Schließen Ihres Internet-Browsers werden diese Session-Cookies gelöscht.</p>

					<p><b>B) DRITTANBIETER-COOKIES</b></p>

					<p class="pb-4">Gegebenenfalls werden mit unserem Internetauftritt auch Cookies von Partnerunternehmen, mit denen wir zum Zwecke der Werbung, der Analyse oder der Funktionalitäten unseres Internetauftritts zusammenarbeiten,
						verwendet.</p>

					<p class="pb-4">Die Einzelheiten hierzu, insbesondere zu den Zwecken und den Rechtsgrundlagen der Verarbeitung solcher Drittanbieter-Cookies, entnehmen Sie bitte den nachfolgenden Informationen.</p>

					<p><b>C) BESEITIGUNGSMÖGLICHKEIT</b></p>

					<p class="pb-4">Sie können die Installation der Cookies durch eine Einstellung Ihres Internet-Browsers verhindern oder einschränken. Ebenfalls können Sie bereits gespeicherte Cookies jederzeit löschen. Die hierfür erforderlichen
						Schritte
						und Maßnahmen hängen jedoch von Ihrem konkret genutzten Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die Hilfefunktion oder Dokumentation Ihres Internet-Browsers oder wenden sich an dessen Hersteller
						bzw. Support.
						Bei sog. Flash-Cookies kann die Verarbeitung allerdings nicht über die Einstellungen des Browsers unterbunden werden. Stattdessen müssen Sie insoweit die Einstellung Ihres Flash-Players ändern. Auch die hierfür
						erforderlichen
						Schritte und Maßnahmen hängen von Ihrem konkret genutzten Flash-Player ab. Bei Fragen benutzen Sie daher bitte ebenso die Hilfefunktion oder Dokumentation Ihres Flash-Players oder wenden sich an den Hersteller
						bzw.
						Benutzer-Support.</p>

					<p class="pb-4">Sollten Sie die Installation der Cookies verhindern oder einschränken, kann dies allerdings dazu führen, dass nicht sämtliche Funktionen unseres Internetauftritts vollumfänglich nutzbar sind.</p>

					<h5 class="font-semibold pb-2">Kontaktanfragen / Kontaktmöglichkeit</h5>

					<p class="pb-4">Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt treten, werden die dabei von Ihnen angegebenen Daten zur Bearbeitung Ihrer Anfrage genutzt. Die Angabe der Daten ist zur Bearbeitung und Beantwortung
						Ihre Anfrage
						erforderlich – ohne deren Bereitstellung können wir Ihre Anfrage nicht oder allenfalls eingeschränkt beantworten.</p>

					<p class="pb-4">Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.</p>

					<p class="pb-4">Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend beantwortet worden ist und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen, wie bspw. bei einer sich etwaig anschließenden
						Vertragsabwicklung.</p>

					<h5 class="font-semibold pb-2">Serverdaten</h5>

					<p class="pb-4">Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren und stabilen Internetauftritts, werden Daten durch Ihren Internet-Browser an uns bzw. an unseren Webspace-Provider übermittelt. Mit diesen
						sog.
						Server-Logfiles werden u.a. Typ und Version Ihres Internetbrowsers, das Betriebssystem, die Website, von der aus Sie auf unseren Internetauftritt gewechselt haben (Referrer URL), die Website(s) unseres
						Internetauftritts,
						die Sie besuchen, Datum und Uhrzeit des jeweiligen Zugriffs sowie die IP-Adresse des Internetanschlusses, von dem aus die Nutzung unseres Internetauftritts erfolgt, erhoben.</p>

					<p class="pb-4">Diese so erhobenen Daten werden vorrübergehend gespeichert, dies jedoch nicht gemeinsam mit anderen Daten von Ihnen.</p>

					<p class="pb-4">Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Verbesserung, Stabilität, Funktionalität und Sicherheit unseres Internetauftritts.</p>

					<p class="pb-4">Die Daten werden spätestens nach sieben Tage wieder gelöscht, soweit keine weitere Aufbewahrung zu Beweiszwecken erforderlich ist. Andernfalls sind die Daten bis zur endgültigen Klärung eines Vorfalls ganz oder
						teilweise
						von der Löschung ausgenommen.</p>

					<h5 class="font-semibold pb-2">Google Fonts (lokales Hosting)</h5>

					<p class="pb-4">Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Google Fonts, die von Google bereitgestellt werden. Die Google Fonts sind lokal installiert.
						Eine Verbindung zu Servern von Google findet dabei nicht statt.</p>

					<p class="pb-4">Weitere Informationen zu Google Fonts finden Sie unter <a href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a> und in der Datenschutzerklärung
						von Google: <a href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a>.</p>

					<h5 class="font-semibold pb-2">Cloudflare Turnstile</h5>

					<p class="pb-4">Wir nutzen Cloudflare Turnstile (im Folgenden „Turnstile“) auf dieser Website. Anbieter ist die Cloudflare Inc., 101 Townsend St., San Francisco, CA 94107, USA (im Folgenden
						„Cloudflare”).</p>

					<p class="pb-4">Mit Turnstile soll überprüft werden, ob die Dateneingabe auf dieser Website (z. B. in einem Kontaktformular) durch einen Menschen oder durch ein automatisiertes Programm
						erfolgt. Hierzu analysiert Turnstile das Verhalten des Websitebesuchers anhand verschiedener Merkmale.</p>

					<p class="pb-4">Diese Analyse beginnt automatisch, sobald der Websitebesucher eine Website mit aktiviertem Turnstile betritt. Zur Analyse wertet Turnstile verschiedene Informationen aus
						(z. B. IP-Adresse, Verweildauer des Websitebesuchers auf der Website oder vom Nutzer getätigte Mausbewegungen). Die bei der Analyse erfassten Daten werden an Cloudflare
						weitergeleitet.</p>

					<p class="pb-4">Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse daran, seine Webangebote vor
						missbräuchlicher automatisierter Ausspähung und vor SPAM zu schützen. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
						Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des
						Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.</p>

					<p class="pb-4">
						Die Datenverarbeitung wird auf Standardvertragsklauseln gestützt, die sie hier finden: <a href="https://www.cloudflare.com/cloudflare-customer-scc">https://www.cloudflare.com/cloudflare-customer-scc</a>.<br/>
						Weitere Informationen zu Cloudflare Turnstile entnehmen Sie den Datenschutzbestimmungen unter <a href="https://www.cloudflare.com/cloudflare-customer-dpa">https://www.cloudflare.com/cloudflare-customer-dpa</a>.
					</p>

					<h5 class="font-semibold pb-2">OpenStreetMap</h5>

					<p class="pb-4">Für Anfahrtsbeschreibungen setzen wir OpenStreetMap, einen Dienst der OpenStreetMap Foundation, St John’s Innovation Centre, Cowley Road, Cambridge, CB 4 0 WS, United Kingdom, nachfolgend nur „OpenStreetMap“
						genannt, ein.</p>

					<p class="pb-4">Bei Aufruf einer unserer Internetseiten, in die der Dienst OpenStreetMap eingebunden ist, wird durch OpenStreetMap ein Cookie über Ihren Internet-Browser auf Ihrem Endgerät gespeichert. Hierdurch werden Ihre
						Nutzereinstellungen und Nutzerdaten zum Zwecke der Anzeige der Seite bzw. zur Gewährleistung der Funktionalität des Dienstes OpenStreetMap verarbeitet. Durch diese Verarbeitung kann OpenStreetMap erkennen, von
						welcher
						Internetseite Ihre Anfrage gesendet worden ist und an welche IP- Adresse die Darstellung der Anfahrt übermittelt werden soll.</p>

					<p class="pb-4">Im Falle einer von Ihnen erteilten Einwilligung für diese Verarbeitung ist Rechtsgrundlage Art. 6 Abs. 1 lit. a DSGVO. Rechtsgrundlage kann auch Art. 6 Abs. 1 lit. f DSGVO sein. Unser berechtigtes Interesse liegt
						in der
						Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts.</p>

					<p class="pb-4">Sofern Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die Installation der Cookies durch die entsprechenden Einstellungen in Ihrem Internet-Browser zu verhindern. Einzelheiten
						hierzu finden
						Sie vorstehend unter dem Punkt „Cookies“.</p>

					<p class="pb-4">OpenStreetMap bietet unter <a href="https://wiki.osmfoundation.org/wiki/Privacy_Polic" target="_blank">https://wiki.osmfoundation.org/wiki/Privacy_Policy</a> weitere Informationen zur Erhebung und Nutzung der
						Daten sowie zu
						Ihren Rechten und Möglichkeiten zum Schutz Ihrer Privatsphäre an.</p>

					<p class="pb-4">
						<a href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html" target="_blank" class="font-semibold">Muster-Datenschutzerklärung</a> der
						<a href="https://www.ratgeberrecht.eu" target="_blank" class="font-semibold">Anwaltskanzlei Weiß &amp; Partner</a>
					</p>

				</div>

			</div>

		</div>

	</section>

	<section class="pt-6 bg-dark text-white">
		<Info />
	</section>

	<Footer/>

	<ScrollToTop/>
</template>

<script setup>
import {onMounted} from "vue";

import {useLoaderState} from "@/store/isloading";

import Navbar from "@/components/layout/navbar.vue";
import Info from "@/components/layout/info.vue";
import Footer from "@/components/layout/footer.vue";
import ScrollToTop from "@/components/scroll-to-top.vue";

const isLoading = useLoaderState();
const { changeStateTrue } = isLoading;

onMounted(() => {
	changeStateTrue();

	setTimeout(() => {
		const { changeStateFalse } = isLoading;
		changeStateFalse();
	}, 1000);
})
</script>
