<template>
	<!-- Start Footer -->
	<footer class="py-4 bg-dark dark:bg-dark">
		<div class="container">
			<div class="grid grid-cols-1 items-center">

				<div class="md:mt-0 mt-8">
					<div class="text-center">
						<p class="text-white">© {{ year }} Bogenschützen Münster e.V. von 1979</p>
					</div>
				</div>

			</div><!--end row-->
		</div><!--end container-->
	</footer><!--end footer-->
	<!-- End Footer -->
</template>

<script setup>
import {ref} from 'vue';

const year = ref(new Date().getFullYear());
</script>
