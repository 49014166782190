<template>
	<div>
		<Navbar/>

      <section class="relative" id="home">
         <Hero />
      </section>

<!--	  <section class="relative py-32 bg-slate-50/50 dake:bg-slate-800/20" id="news">-->
<!--		  <News />-->
<!--	  </section>-->

		<!-- Start -->
		<section class="relative overflow-hidden py-48" id="about">
			<About/>
		</section>
		<!-- End -->

		<!-- Start -->
		<section class="relative py-48 bg-slate-50/50" id="team">
			<Team/>
		</section>
		<!-- End -->

		<!-- Start -->
		<section class="relative overflow-hidden py-48" id="membership">
			<Membership/>
		</section>
		<!-- End Section-->

      <section class="relative py-48 bg-slate-50/50" id="events">
         <Events/>
      </section>

		<!-- Start -->
		<section class="relative py-48" id="photos">
			<Photos/>
		</section>
		<!-- End -->

		<!-- Start -->
		<section class="relative py-48 bg-slate-50/50 " id="contact">
			<Contact/>
		</section>
		<!-- End -->

		<!-- Start Info -->
		<section class="py-6 bg-dark text-white">
			<Info />
		</section>
		<!-- End Info -->

		<Footer/>

		<ScrollToTop/>
<!--		<Switcher/>-->

	</div>
</template>

<script setup lang="ts">
import {onMounted} from "vue";

import {useLoaderState} from "@/store/isloading";

import Navbar from '@/components/layout/navbar.vue';
import Hero from "@/components/index/hero.vue";
import About from "@/components/index/about.vue";
import Team from '@/components/index/team.vue';
import Membership from "@/components/index/membership.vue";
import Events from "@/components/index/events.vue";
import Photos from "@/components/index/photos.vue";
import Contact from '@/components/index/contact.vue';
import Info from '@/components/layout/info.vue';
import Footer from '@/components/layout/footer.vue';
import ScrollToTop from '@/components/scroll-to-top.vue';

const isLoading = useLoaderState();
const { changeStateTrue } = isLoading;

onMounted(() => {
   changeStateTrue();

   setTimeout(() => {
      const { changeStateFalse } = isLoading;
      changeStateFalse();
   }, 500);
})
</script>
