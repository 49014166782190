<template>
	<Navbar/>

	<section id="downloads" class="relative py-32">

		<div class="container relative">

			<div class="title grid grid-cols-1 mb-8 text-center">
				<h4 class="text-4xl font-bold mb-3">Downloads</h4>
				<div class="border"></div>
			</div>

			<div class="grid grid-cols-1">
				<div class="block">
					<div class="mt-5">
						<ul class="list-disc list-inside">
							<li><a href="data/Aufnahmeantrag-2021.pdf" target="_blank">Aufnahmeantrag</a></li>
							<li><a href="data/Schieß-und-Platzordnung-2024.pdf" target="_blank">Schie&szlig;- und Platzordnung</a></li>
							<li><a href="data/Satzung2024.pdf" target="_blank">Satzung</a></li>
							<li><a href="data/Reisekostenabrechnung-2019.pdf" target="_blank">Reisekostenabrechnung</a></li>
						</ul>
					</div>
<!--					<div class="mt-5">-->
<!--						<h4 class="font-semibold">Links</h4>-->
<!--						<ul class="list-disc list-inside">-->
<!--							<li>...</li>-->
<!--						</ul>-->
<!--					</div>-->
				</div>
			</div>

		</div>

	</section>

	<section class="pt-6 bg-dark text-white">
		<Info/>
	</section>

	<Footer/>

	<ScrollToTop/>
</template>

<script setup>
import {onMounted} from "vue";
import {useLoaderState} from "@/store/isloading";

import Navbar from "@/components/layout/navbar.vue";
import Info from "@/components/layout/info.vue";
import Footer from "@/components/layout/footer.vue";
import ScrollToTop from "@/components/scroll-to-top.vue";

const isLoading = useLoaderState();
const { changeStateTrue } = isLoading;

onMounted(() => {
	changeStateTrue();

	setTimeout(() => {
		const { changeStateFalse } = isLoading;
		changeStateFalse();
	}, 1000);
})
</script>
