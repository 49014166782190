import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "relative",
  id: "home"
}
const _hoisted_2 = {
  class: "relative overflow-hidden py-48",
  id: "about"
}
const _hoisted_3 = {
  class: "relative py-48 bg-slate-50/50",
  id: "team"
}
const _hoisted_4 = {
  class: "relative overflow-hidden py-48",
  id: "membership"
}
const _hoisted_5 = {
  class: "relative py-48 bg-slate-50/50",
  id: "events"
}
const _hoisted_6 = {
  class: "relative py-48",
  id: "photos"
}
const _hoisted_7 = {
  class: "relative py-48 bg-slate-50/50",
  id: "contact"
}
const _hoisted_8 = { class: "py-6 bg-dark text-white" }

import {onMounted} from "vue";

import {useLoaderState} from "@/store/isloading";

import Navbar from '@/components/layout/navbar.vue';
import Hero from "@/components/index/hero.vue";
import About from "@/components/index/about.vue";
import Team from '@/components/index/team.vue';
import Membership from "@/components/index/membership.vue";
import Events from "@/components/index/events.vue";
import Photos from "@/components/index/photos.vue";
import Contact from '@/components/index/contact.vue';
import Info from '@/components/layout/info.vue';
import Footer from '@/components/layout/footer.vue';
import ScrollToTop from '@/components/scroll-to-top.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const isLoading = useLoaderState();
const { changeStateTrue } = isLoading;

onMounted(() => {
   changeStateTrue();

   setTimeout(() => {
      const { changeStateFalse } = isLoading;
      changeStateFalse();
   }, 500);
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(Navbar),
    _createElementVNode("section", _hoisted_1, [
      _createVNode(Hero)
    ]),
    _createElementVNode("section", _hoisted_2, [
      _createVNode(About)
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createVNode(Team)
    ]),
    _createElementVNode("section", _hoisted_4, [
      _createVNode(Membership)
    ]),
    _createElementVNode("section", _hoisted_5, [
      _createVNode(Events)
    ]),
    _createElementVNode("section", _hoisted_6, [
      _createVNode(Photos)
    ]),
    _createElementVNode("section", _hoisted_7, [
      _createVNode(Contact)
    ]),
    _createElementVNode("section", _hoisted_8, [
      _createVNode(Info)
    ]),
    _createVNode(Footer),
    _createVNode(ScrollToTop)
  ]))
}
}

})