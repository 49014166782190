import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "container relative" }
const _hoisted_2 = {
  class: "grid grid-cols-1 pb-6 text-center",
  "data-aos": "fade-up",
  "data-aos-duration": "500"
}
const _hoisted_3 = { class: "flex flex-row justify-center gap-5" }
const _hoisted_4 = { class: "grid grid-cols-1 lg:grid-cols-4 mt-6 relative" }
const _hoisted_5 = ["onClick", "data-aos-delay"]
const _hoisted_6 = ["src"]

import {computed, ref} from 'vue';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import photos from '@/assets/json/photos.json';


export default /*@__PURE__*/_defineComponent({
  __name: 'photos',
  setup(__props) {

const activeFilter = ref('site');
const visibleRef = ref(false)
const indexRef = ref(0)

const images = computed(() => {
	if(activeFilter.value === undefined){
		activeFilter.value = 'site'
	}
	return photos.filter((item) => item.group === activeFilter.value).map((item) => item.photo);
})

const filterImages = (filter: string) => {
	activeFilter.value = filter;
}

const showImg = (index: number) => {
	indexRef.value = index
	visibleRef.value = true
}

const onHide = () => visibleRef.value = false

return (_ctx: any,_cache: any) => {
  const _component_vue_easy_lightbox = _resolveComponent("vue-easy-lightbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "title grid grid-cols-1 pb-6 text-center",
        "data-aos": "fade-down",
        "data-aos-duration": "500"
      }, [
        _createElementVNode("h1", { class: "font-semibold text-4xl mb-3" }, "Fotos"),
        _createElementVNode("div", { class: "border" })
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("a", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (filterImages('site'))),
            class: _normalizeClass(["filter py-2", { active: activeFilter.value === 'site'}])
          }, "Gelände", 2),
          _createElementVNode("a", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (filterImages('shooting'))),
            class: _normalizeClass(["filter py-2", { active: activeFilter.value === 'shooting'}])
          }, "Bogenschießen", 2),
          _createElementVNode("a", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (filterImages('3d'))),
            class: _normalizeClass(["filter py-2", { active: activeFilter.value === '3d'}])
          }, "3D", 2)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(images.value, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item,
            class: "m-3 photo-wrapper",
            onClick: () => showImg(index),
            "data-aos": "zoom-in",
            "data-aos-duration": "500",
            "data-aos-delay": 100 * index,
            "data-aos-once": "false"
          }, [
            _createElementVNode("img", {
              src: item,
              alt: "image",
              class: "w-80 xl:w-full justify-self-center"
            }, null, 8, _hoisted_6),
            _createVNode(_unref(FontAwesomeIcon), {
              icon: "fa-solid fa-magnifying-glass-plus",
              size: "xl",
              class: "icon"
            })
          ], 8, _hoisted_5))
        }), 128))
      ])
    ]),
    _createVNode(_component_vue_easy_lightbox, {
      visible: visibleRef.value,
      imgs: images.value,
      index: indexRef.value,
      onHide: onHide
    }, null, 8, ["visible", "imgs", "index"])
  ], 64))
}
}

})