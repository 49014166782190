import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container relative" }
const _hoisted_2 = { class: "grid grid-cols-1 pb-6" }
const _hoisted_3 = ["data-aos-delay"]
const _hoisted_4 = { class: "grid grid-cols-4 lg:grid-cols-9 w-80%" }
const _hoisted_5 = { class: "row-span-4 lg:row-span-2 lg:text-4xl text-4xl font-semibold text-center content-center lg:px-4 mr-5 border-r-2" }
const _hoisted_6 = { class: "col-span-3 lg:col-span-8 py-2" }
const _hoisted_7 = { class: "text-xl font-semibold" }
const _hoisted_8 = { class: "col-span-3 lg:col-span-2 pb-2" }
const _hoisted_9 = {
  key: 0,
  class: "col-span-3 lg:col-span-2 pb-2"
}
const _hoisted_10 = { class: "col-span-3 lg:col-span-4 pb-2" }
const _hoisted_11 = { class: "px-8 py-4" }

import {ref} from "vue";
import { DateTime } from 'luxon';

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";


export default /*@__PURE__*/_defineComponent({
  __name: 'events',
  setup(__props) {

const events = ref('');

const getEvents = () => {
   fetch('api/calendar.php', {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      }
   })
         .then(response => response.json())
         .then(data => events.value = data)
         .catch(error => console.log(error));
}

getEvents();

function formatDay(dateString: string): string {
   return DateTime.fromISO(dateString)
         .setLocale('de')
         .toFormat('ccc');
}

function formatDate(dateString: string): string {
   return DateTime.fromISO(dateString)
         .setLocale('de')
         .toFormat('dd.MMMM.yyyy');
}

function formatTime(dateString: string): string {
   const time = DateTime.fromISO(dateString)
         .setLocale('de')
         .toFormat('HH:mm');
   if (time == '00:00') {
      return 't.b.a.';
   }
   return time.concat(' Uhr');
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("div", {
      class: "title grid grid-cols-1 pb-6 text-center",
      "data-aos": "fade-down",
      "data-aos-duration": "500"
    }, [
      _createElementVNode("h1", { class: "font-semibold text-4xl mb-3" }, "Termine"),
      _createElementVNode("div", { class: "border" })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(events.value, (event, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: event.id,
            class: "event py-2",
            "data-aos": "fade-up",
            "data-aos-duration": "500",
            "data-aos-delay": index * 200
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(formatDay(event.start)), 1),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h4", _hoisted_7, _toDisplayString(event.name), 1)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_unref(FontAwesomeIcon), {
                  icon: "fa-solid fa-calendar",
                  class: "pr-3"
                }),
                _createTextVNode(" " + _toDisplayString(formatDate(event.start)), 1)
              ]),
              (formatTime(event.start))
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_unref(FontAwesomeIcon), {
                      icon: "fa-solid fa-clock",
                      class: "pr-3"
                    }),
                    _createTextVNode(" " + _toDisplayString(formatTime(event.start)), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_unref(FontAwesomeIcon), {
                  icon: "fa-solid fa-location-dot",
                  class: "pr-3"
                }),
                _createTextVNode(" " + _toDisplayString(event.locationName), 1)
              ])
            ])
          ], 8, _hoisted_3))
        }), 128)),
        _createElementVNode("li", _hoisted_11, [
          _createVNode(_component_router_link, {
            to: "/events",
            class: "text-lg text-black hover:text-yellow-500"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("weitere Termine")
            ])),
            _: 1
          })
        ])
      ])
    ])
  ]))
}
}

})