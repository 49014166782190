import { createApp } from 'vue'
import {createPinia} from "pinia"

import App from './App.vue'
import router from './router'

import './assets/css/tailwind.css'

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(fas, far);

import AOS from 'aos';
import 'aos/dist/aos.css';

import VueEasyLightbox from 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js'
import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css'

import cookieconsentvue from "./plugins/cookieconsentvue.js";

const pinia = createPinia()

AOS.init({
    startEvent: 'load'
});

createApp(App)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(router)
    .use(VueEasyLightbox)
    .use(cookieconsentvue)
    .use(pinia)
    .mount('#app')
