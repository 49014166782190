<template>
      <nav class="navbar" :class="scroll ? 'is-sticky' : 'bg-dark'" id="navbar" >
        <div class="container relative flex flex-wrap items-center justify-between">
            <router-link v-if="!navLight" class="navbar-brand md:me-8" to="/index">
                <img :src="logoDark" class="h-10 inline-block dark:hidden" alt="" />
                <img :src="logoLight" class="h-10 hidden dark:inline-block" alt="" />
            </router-link>

            <router-link v-if="navLight" class="navbar-brand" to="/">
                <span class="inline-block dark:hidden">
                    <img :src="logoDark" class="l-dark" alt="" />
                    <img :src="logoLight" class="l-light" alt="" />
                </span>
                <img :src="logoLight" class="hidden dark:inline-block" alt="" />
            </router-link>

            <div class="nav-icons flex items-center lg_992:order-2 md:ms-6">

                <ul v-if="!playBtn" class="list-none menu-social mb-0">
                    <li class="inline">
                        <router-link to="" class="h-8 px-4 text-[12px] tracking-wider inline-flex items-center justify-center font-medium rounded-full bg-yellow-500 text-black">easyVerein</router-link>
                    </li>
                </ul>

                <button type="button" class="collapse-btn inline-flex items-center ms-2 text-slate-900 dark:text-white lg_992:hidden" @click.native="handler()">
                    <span class="sr-only">Navigation Menu</span>
	                <FontAwesomeIcon icon="fa-solid fa-bars" size="xl" />
                </button>
            </div>

            <div class="navigation lg_992:order-1 lg_992:flex ms-auto" id="menu-collapse" :class="toggle ? '' : 'hidden'">
                <ul class="navbar-nav" :class="navLight ? 'nav-light' : ''" id="navbar-navlist">
                    <li class="nav-item home active">
                        <router-link class="nav-link active" to="index">Home</router-link>
                    </li>
                    <li class="nav-item about ms-0">
                        <router-link class="nav-link" to="index#about">Unser Verein</router-link>
                    </li>
                    <li class="nav-item team ms-0">
                        <router-link class="nav-link" to="index#team">Vorstand</router-link>
                    </li>
                    <li class="nav-item membership ms-0">
                        <router-link class="nav-link" to="index#membership">Mitgliedschaft</router-link>
                    </li>
                   <li class="nav-item events ms-0">
                      <router-link class="nav-link" to="index#events">Termine</router-link>
                   </li>
                    <li class="nav-item photos ms-0">
                        <router-link class="nav-link" to="index#photos">Fotos</router-link>
                    </li>
                    <li class="nav-item contact ms-0">
                        <router-link class="nav-link" to="index#contact">Kontakt</router-link>
                    </li>
                </ul>
            </div>
        </div>
      </nav>
</template>

<script setup>
    import { ref, onMounted, onUnmounted } from 'vue';
    import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

    import logoDark from '@/assets/images/logo-small.png'
    import logoLight from '@/assets/images/logo-small.png'

    defineProps({
        navLight:Boolean,
        playBtn:Boolean,
        bg:Boolean,
    })

    const toggle = ref(false)
    const sections = ref([])
    const navLi = ref([])
    const current = ref("")
    const sectionTop = ref(null)

    const scroll = ref(false)

    onMounted(()=>{
        sections.value = document.querySelectorAll("section")
        navLi.value = document.querySelectorAll("nav .container .navigation ul li")

        window.addEventListener('scroll', onscroll)
        window.addEventListener('scroll', handleScroll);
    })

    onUnmounted(() => {
        window.removeEventListener('scroll', onscroll);
        window.removeEventListener('scroll', handleScroll);
    })

    function onscroll() {
        sections.value.forEach((section) => {
        
            sectionTop.value = section.offsetTop
            if (pageYOffset >= sectionTop.value - 60) {
                current.value = section.getAttribute("id")
            }
        })
        navLi.value.forEach((li) => {
            if (li.classList.contains(current.value)) {
                li.classList.add("active")
            } else {
                li.classList.remove("active")
            }
        })
    }

    const handleScroll = () => {
        if (window.scrollY >= 50) {
            scroll.value = true
        } else {
            scroll.value = false
        }
    }

    const handler = () => {
        toggle.value = !toggle.value
    }
</script>
