<template>
	<div class="container relative">
		<div class="grid grid-cols-1 lg:grid-cols-3 gap-[30px]">
			<div class="w-80%">
				<h5 class="text-lg font-semibold">INFO</h5>
				<ul class="pt-2 navigation">
					<li class="nav-link"><router-link to="journey">Anfahrt</router-link></li>
					<li class="nav-link"><router-link to="events">Termine</router-link></li>
					<li class="nav-link"><router-link to="downloads">Downloads</router-link></li>
					<li class="nav-link"><router-link to="rules">Schie&szlig;- &amp; Platzordnung</router-link></li>
					<li class="nav-link"><router-link to="statute">Satzung</router-link></li>
				</ul>
			</div>
			<div class="w-80%">
				<h5 class="text-lg font-semibold">RECHTLICHES</h5>
				<ul class="pt-2 navigation">
					<li class="nav-link"><a v-on:click="$CC.showPreferences()">Cookie-Einstellungen</a></li>
					<li class="nav-link"><router-link to="privacy-policy">Datenschutzerkl&auml;rung</router-link></li>
					<li class="nav-link"><router-link to="impressum">Impressum</router-link></li>
				</ul>
			</div>
			<div class="w-80%">
				<h5 class="text-lg font-semibold">MITGLIEDERBEREICH</h5>
				<ul class="pt-2 navigation">
					<li class="nav-link"><a href="https://www.easyverein.com/public/BSM" title="Mitgliederbereich Vereinsverwaltung" target="_blank">easyVerein</a></li>
				</ul>
			</div>
		</div>
	</div>
</template>

<style scoped>
.navigation .nav-link:hover {
   color: #fbba00;
   --tw-text-opacity: 1;
   opacity: var(--tw-text-opacity);
}
</style>

