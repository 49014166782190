<template>
    <div class="container relative">
        <div class="title grid grid-cols-1 pb-6 text-center" data-aos="fade-down" data-aos-duration="500">
	        <h1 class="font-semibold text-4xl mb-3">Fotos</h1>
	        <div class="border"></div>
        </div>

	    <div class="grid grid-cols-1 pb-6 text-center" data-aos="fade-up" data-aos-duration="500">
		    <div class="flex flex-row justify-center gap-5">
			    <a @click="filterImages('site')" class="filter py-2" :class="{ active: activeFilter === 'site'}">Gelände</a>
			    <a @click="filterImages('shooting')" class="filter py-2" :class="{ active: activeFilter === 'shooting'}">Bogenschie&szlig;en</a>
			    <a @click="filterImages('3d')" class="filter py-2" :class="{ active: activeFilter === '3d'}">3D</a>
		    </div>
	    </div>

        <div class="grid grid-cols-1 lg:grid-cols-4 mt-6 relative">
	        <div v-for="(item, index) in images" :key="item" class="m-3 photo-wrapper" @click="() => showImg(index)" data-aos="zoom-in" data-aos-duration="500" :data-aos-delay="100 * index" data-aos-once="false">
		        <img :src="item" alt="image" class="w-80 xl:w-full justify-self-center">
		        <FontAwesomeIcon icon="fa-solid fa-magnifying-glass-plus" size="xl" class="icon" />
	        </div>
        </div>
    </div>
	<vue-easy-lightbox :visible="visibleRef" :imgs="images" :index="indexRef" @hide="onHide"></vue-easy-lightbox>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import photos from '@/assets/json/photos.json';

const activeFilter = ref('site');
const visibleRef = ref(false)
const indexRef = ref(0)

const images = computed(() => {
	if(activeFilter.value === undefined){
		activeFilter.value = 'site'
	}
	return photos.filter((item) => item.group === activeFilter.value).map((item) => item.photo);
})

const filterImages = (filter: string) => {
	activeFilter.value = filter;
}

const showImg = (index: number) => {
	indexRef.value = index
	visibleRef.value = true
}

const onHide = () => visibleRef.value = false
</script>

<style scoped>
img {
	max-width: 400px;
}

.photo-wrapper {
	position: relative;
	display: inline-block;
;
}

.photo-wrapper:hover .icon {
	display: block;
}

.photo-wrapper .icon {
	color: #ffffff;
	position: absolute;
	top: 50%;
	left: 50%;
	display: none;
}

.filter {
	text-align: center;
	width: 10rem;
}

.active {
	border: 1px solid #fbba00;
}
</style>
