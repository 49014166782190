<template>
	<Navbar/>

	<section id="rules" class="relative py-32">

		<div class="container relative">

			<div class="title grid grid-cols-1 mb-8 text-center">
				<h4 class="uppercase text-4xl font-bold tracking-wider mb-3">Termine</h4>
				<div class="border"></div>
			</div>

			<div class="grid grid-cols-1">
				<div class="block">

					<h5 class="font-semibold">Verein</h5>
					<ul class="my-5 monospace">
						<li>23.02. 18:00 Uhr - Jahreshauptversammlung</li>
						<li>22.03. 10.00 Uhr - Arbeitstag am Platz</li>
					</ul>

					<h5 class="font-semibold">Turniere/Meisterschaften</h5>
					<ul class="my-5 monospace">
						<li>26.04. - Vereinsmeisterschaft Bogen im Freien (voraussichtlich 14.00 Uhr)</li>
						<li>27.04. - Vereinsmeisterschaft Feldbogen (voraussichtlich. 10.00 Uhr)</li>
						<li>04.05. - Bezirksmeisterschaft Feldbogen, Münster</li>
						<li>25.05. - Bezirksmeisterschaft Bogen im Freien, Bocholt</li>
						<li>01.06. - Landesmeisterschaft Feldbogen, Dorsten</li>
						<li>06.07. - Landesmeisterschaft Bogen im Freien (Blankbogen), Bochum</li>
						<li>12.07. - Landesmeisterschaft Bogen im Freien (Recurve), Bochum/Dortmund</li>
						<li>13.07. - Landesmeisterschaft Bogen im Freien (Compound), Bochum/Dortmund</li>
						<li>27.07. - Landesmeisterschaft 3D, Münster</li>
						<li>09.-10.08. - Deutsche Meisterschaft Feldbogen, Delmenhorst</li>
						<li>23.-24.08. - Deutsche Meisterschaft 3D, Bad Kreuznach,</li>
						<li>05.-07.09. - Deutsche Meisterschaft Bogen im Freien, Wiesbaden</li>
					</ul>

				</div>
			</div>

		</div>

	</section>

	<section class="pt-6 bg-dark text-white">
		<Info/>
	</section>

	<Footer/>

	<ScrollToTop/>
</template>

<script setup>
import {onMounted} from "vue";

import {useLoaderState} from "@/store/isloading";

import Navbar from "@/components/layout/navbar.vue";
import Info from "@/components/layout/info.vue";
import Footer from "@/components/layout/footer.vue";
import ScrollToTop from "@/components/scroll-to-top.vue";

const isLoading = useLoaderState();
const { changeStateTrue } = isLoading;

onMounted(() => {
	changeStateTrue();

	setTimeout(() => {
		const { changeStateFalse } = isLoading;
		changeStateFalse();
	}, 1000);
})
</script>
