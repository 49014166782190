import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, isRef as _isRef, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container relative" }
const _hoisted_2 = { class: "grid md:grid-cols-12 grid-cols-1 gap-[30px] mb-40" }
const _hoisted_3 = {
  class: "lg:col-span-8 md:col-span-6",
  "data-aos": "fade-left",
  "data-aos-duration": "500"
}
const _hoisted_4 = { class: "lg:ms-5" }
const _hoisted_5 = { class: "bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6" }
const _hoisted_6 = { class: "grid lg:grid-cols-12 gap-3" }
const _hoisted_7 = { class: "lg:col-span-12" }
const _hoisted_8 = { class: "text-start" }
const _hoisted_9 = { class: "lg:col-span-12" }
const _hoisted_10 = { class: "text-start" }
const _hoisted_11 = { class: "lg:col-span-12" }
const _hoisted_12 = { class: "text-start" }
const _hoisted_13 = { class: "lg:col-span-12" }
const _hoisted_14 = { class: "text-start" }
const _hoisted_15 = { class: "lg:col-span-12" }
const _hoisted_16 = ["disabled"]
const _hoisted_17 = {
  key: 0,
  class: "lg:col-span-12 p-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300",
  role: "alert"
}

import {ref} from "vue";

import {Turnstile} from '@sctg/turnstile-vue3';

import emailjs from 'emailjs-com';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const siteKey = '0x4AAAAAAAD4ao0qjjaId4P4';

export default /*@__PURE__*/_defineComponent({
  __name: 'contact',
  setup(__props) {

let token = '';

const captchaVerified = ref(false);

const name = ref('');
const email = ref('');
const subject = ref('');
const message = ref('');
const progress = ref(false);
const success = ref('');

const verifyCaptcha = () => {
   console.log('token: ', token);
   fetch('api/verify-captcha.php', {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
      body: JSON.stringify({token}),
   })
         .then((response) => response.json())
         .then((data) => captchaVerified.value = data.success)
         .catch((error) => console.error(error));
}

const expiredCaptcha = () => {
   captchaVerified.value = false;
}

function sendMail() {
   progress.value = true;
   const param = {
      name: name.value,
      email: email.value,
      subject: subject.value,
      message: message.value
   };

   emailjs.send('service_8vffpcb', 'template_825lfrj', param, 'kDgo-UKHosmOcMSqV')
         .then(() => {
                  name.value = '';
                  email.value = '';
                  subject.value = '';
                  message.value = '';
                  success.value = 'E-Mail erfolgreich versendet.';
                  progress.value = false;
               },
               (error) => {
                  console.error('FAILED...', error.text);
                  success.value = 'E-Mail konnte nicht versendet werden!';
                  progress.value = false;
               },
         );
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "lg:col-span-4 md:col-span-6" }, [
        _createElementVNode("div", {
          class: "title text-center",
          "data-aos": "fade-right",
          "data-aos-duration": "500"
        }, [
          _createElementVNode("h4", { class: "font-anaheim font-semibold text-4xl mb-3" }, "Kontakt"),
          _createElementVNode("div", { class: "border" }),
          _createElementVNode("p", { class: "mt-20 text-left" }, [
            _createTextVNode(" Ihr könnt uns eine Nachricht über das Kontaktformular senden oder direkt an die "),
            _createElementVNode("a", {
              href: "#",
              class: "encrypted-mail font-semibold",
              "data-name": "kontakt",
              "data-domain": "bogenschuetzen-muenster",
              "data-tld": "de",
              onclick: "window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"
            }),
            _createTextVNode(" mailen. ")
          ])
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("form", {
              id: "contact-form",
              ref: "form",
              onSubmit: _withModifiers(sendMail, ["prevent"])
            }, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _withDirectives(_createElementVNode("input", {
                      name: "name",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((name).value = $event)),
                      type: "text",
                      placeholder: "Name",
                      required: "",
                      class: "form-input mt-1 w-full py-2 px-3 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 focus:border-yellow-500 dark:border-gray-800 dark:focus:border-red-500 focus:ring-0"
                    }, null, 512), [
                      [_vModelText, name.value]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _withDirectives(_createElementVNode("input", {
                      name: "email",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((email).value = $event)),
                      type: "email",
                      placeholder: "Email",
                      required: "",
                      class: "form-input mt-1 w-full py-2 px-3 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 focus:border-yellow-500 dark:border-gray-800 dark:focus:border-red-500 focus:ring-0"
                    }, null, 512), [
                      [_vModelText, email.value]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _withDirectives(_createElementVNode("input", {
                      name: "subject",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((subject).value = $event)),
                      placeholder: "Betreff",
                      required: "",
                      class: "form-input mt-1 w-full py-2 px-3 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 focus:border-yellow-500 dark:border-gray-800 dark:focus:border-red-500 focus:ring-0"
                    }, null, 512), [
                      [_vModelText, subject.value]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _withDirectives(_createElementVNode("textarea", {
                      name: "message",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((message).value = $event)),
                      placeholder: "Nachricht",
                      required: "",
                      class: "form-input mt-1 w-full py-2 px-3 h-80 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 focus:border-yellow-500 dark:border-gray-800 dark:focus:border-red-500 focus:ring-0"
                    }, "\t\t                         ", 512), [
                      [_vModelText, message.value]
                    ])
                  ])
                ]),
                _createVNode(_unref(Turnstile), {
                  "site-key": siteKey,
                  theme: "light",
                  modelValue: _unref(token),
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(token) ? (token).value = $event : token = $event)),
                  onComplete: verifyCaptcha,
                  onExpired: expiredCaptcha
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("button", {
                    type: "submit",
                    id: "submit",
                    name: "send",
                    class: _normalizeClass(["py-2 px-5 tracking-wider inline-flex items-center justify-center font-medium rounded bg-yellow-500 text-black", { 'btn-disabled': !captchaVerified.value }]),
                    disabled: !captchaVerified.value
                  }, [
                    (progress.value)
                      ? (_openBlock(), _createBlock(_unref(FontAwesomeIcon), {
                          key: 0,
                          icon: "fa-solid fa-circle-notch",
                          spin: "",
                          class: "mr-2"
                        }))
                      : _createCommentVNode("", true),
                    _cache[5] || (_cache[5] = _createElementVNode("span", null, "Send Message", -1))
                  ], 10, _hoisted_16)
                ]),
                (success.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(success.value), 1))
                  : _createCommentVNode("", true)
              ])
            ], 544)
          ])
        ])
      ])
    ])
  ]))
}
}

})