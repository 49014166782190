<template>
   <div class="container relative">
      <div class="grid md:grid-cols-12 grid-cols-1 gap-[30px] mb-40">

         <div class="lg:col-span-4 md:col-span-6">

            <div class="title text-center" data-aos="fade-right" data-aos-duration="500">
               <h4 class="font-anaheim font-semibold text-4xl mb-3">Kontakt</h4>
               <div class="border"></div>

               <p class="mt-20 text-left">
                  Ihr könnt uns eine Nachricht über das Kontaktformular senden oder direkt an die
                  <a href="#" class="encrypted-mail font-semibold" data-name="kontakt" data-domain="bogenschuetzen-muenster" data-tld="de"
                     onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"></a>
                  mailen.
               </p>
            </div>

         </div>

         <div class="lg:col-span-8 md:col-span-6" data-aos="fade-left" data-aos-duration="500">
            <div class="lg:ms-5">
               <div class="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">

                  <form id="contact-form" ref="form" @submit.prevent="sendMail">
                     <div class="grid lg:grid-cols-12 gap-3">

                        <div class="lg:col-span-12">
                           <div class="text-start">
                              <input name="name" v-model="name" type="text" placeholder="Name" required
                                     class="form-input mt-1 w-full py-2 px-3 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 focus:border-yellow-500 dark:border-gray-800 dark:focus:border-red-500 focus:ring-0"/>
                           </div>
                        </div>

                        <div class="lg:col-span-12">
                           <div class="text-start">
                              <input name="email" v-model="email" type="email" placeholder="Email" required
                                     class="form-input mt-1 w-full py-2 px-3 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 focus:border-yellow-500 dark:border-gray-800 dark:focus:border-red-500 focus:ring-0"/>
                           </div>
                        </div>

                        <div class="lg:col-span-12">
                           <div class="text-start">
                              <input name="subject" v-model="subject" placeholder="Betreff" required
                                     class="form-input mt-1 w-full py-2 px-3 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 focus:border-yellow-500 dark:border-gray-800 dark:focus:border-red-500 focus:ring-0"/>
                           </div>
                        </div>

                        <div class="lg:col-span-12">
                           <div class="text-start">
		                         <textarea name="message" v-model="message" placeholder="Nachricht" required
                                         class="form-input mt-1 w-full py-2 px-3 h-80 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 focus:border-yellow-500 dark:border-gray-800 dark:focus:border-red-500 focus:ring-0">
		                         </textarea>
                           </div>
                        </div>

                        <Turnstile :site-key="siteKey" theme="light" v-model="token" @complete="verifyCaptcha" @expired="expiredCaptcha" />

                        <div class="lg:col-span-12">
                           <button type="submit" id="submit" name="send" class="py-2 px-5 tracking-wider inline-flex items-center justify-center font-medium rounded bg-yellow-500 text-black"
                                   :class="{ 'btn-disabled': !captchaVerified }" :disabled="!captchaVerified">
                              <FontAwesomeIcon icon="fa-solid fa-circle-notch" spin v-if="progress" class="mr-2" />
                              <span>Send Message</span>
                           </button>
                        </div>

                        <div class="lg:col-span-12 p-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300" role="alert" v-if="success">{{success}}</div>

                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   </div><!--end container-->
</template>

<script setup lang="ts">
import {ref} from "vue";

import {Turnstile} from '@sctg/turnstile-vue3';

import emailjs from 'emailjs-com';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const siteKey = '0x4AAAAAAAD4ao0qjjaId4P4';
let token = '';

const captchaVerified = ref(false);

const name = ref('');
const email = ref('');
const subject = ref('');
const message = ref('');
const progress = ref(false);
const success = ref('');

const verifyCaptcha = () => {
   console.log('token: ', token);
   fetch('api/verify-captcha.php', {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
      body: JSON.stringify({token}),
   })
         .then((response) => response.json())
         .then((data) => captchaVerified.value = data.success)
         .catch((error) => console.error(error));
}

const expiredCaptcha = () => {
   captchaVerified.value = false;
}

function sendMail() {
   progress.value = true;
   const param = {
      name: name.value,
      email: email.value,
      subject: subject.value,
      message: message.value
   };

   emailjs.send('service_8vffpcb', 'template_825lfrj', param, 'kDgo-UKHosmOcMSqV')
         .then(() => {
                  name.value = '';
                  email.value = '';
                  subject.value = '';
                  message.value = '';
                  success.value = 'E-Mail erfolgreich versendet.';
                  progress.value = false;
               },
               (error) => {
                  console.error('FAILED...', error.text);
                  success.value = 'E-Mail konnte nicht versendet werden!';
                  progress.value = false;
               },
         );
}
</script>

<style scoped>
.encrypted-mail {
   cursor: pointer;
}

.encrypted-mail:hover {
   color: #fbba00;
}

.encrypted-mail:after {
   content: attr(data-name) "@" attr(data-domain) "." attr(data-tld);
}
</style>
