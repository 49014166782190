import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "m-4 relative overflow-x-auto"
}
const _hoisted_2 = { class: "text-base w-auto" }
const _hoisted_3 = { class: "odd:bg-gray-50 even:bg-white border-t border-b dark:border-gray-700" }
const _hoisted_4 = {
  scope: "row",
  class: "text-left px-6 py-4 font-medium whitespace-nowrap dark:text-white"
}
const _hoisted_5 = { class: "text-right px-5 py-4" }


export default /*@__PURE__*/_defineComponent({
  __name: 'fees',
  setup(__props) {

const feeData = [
	{
		group: 'Erwachsene',
		amount: 120
	},
	{
		group: 'Studenten, Auszubildende, Rentner, Schwerbehinderte *)',
		amount: 60
	},
	{
		group: 'Kinder, Jugendliche',
		amount: 36
	},
]

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    feeData
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("table", _hoisted_2, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(feeData, (fee) => {
              return _createElementVNode("tr", _hoisted_3, [
                _createElementVNode("th", _hoisted_4, _toDisplayString(fee.group), 1),
                _createElementVNode("td", _hoisted_5, _toDisplayString(fee.amount) + " €", 1)
              ])
            }), 64))
          ])
        ]))
      : _createCommentVNode("", true),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-slate-400 dark:text-gray-400" }, [
      _createElementVNode("p", { class: "my-4" }, "Bei mehreren Familienmitgliedern zahlt der Antragsteller den vollen Beitrag und alle weiteren Familienmitglieder zahlen den halben Beitrag. Ab dem dritten Kind sind diese beitragsfrei."),
      _createElementVNode("p", { class: "my-4" }, "*) bei (jährlicher) Vorlage entsprechender Nachweise")
    ], -1))
  ], 64))
}
}

})