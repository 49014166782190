/**
* @type {import('vanilla-cookieconsent').CookieConsentConfig}
*/
const config = {
    guiOptions: {
        consentModal: {
            layout: "box",
            position: "bottom right",
            equalWeightButtons: true,
            flipButtons: false
        },
        preferencesModal: {
            layout: "box",
            position: "right",
            equalWeightButtons: true,
            flipButtons: false
        }
    },
    categories: {
        necessary: {
            readOnly: true
        },
        functionality: {}
    },
    language: {
        default: "de",
        autoDetect: "browser",
        translations: {
            de: {
                consentModal: {
                    title: "Privatsphäre-Einstellungen",
                    description: "Wir verwenden notwendige Cookies, damit unsere Website funktioniert. Mit Ihrer Zustimmung können wir auch nicht notwendige Cookies verwenden, um die Benutzerfreundlichkeit zu verbessern und den Traffic " +
                        "auf unserer Website zu analysieren. Indem Sie auf „Akzeptieren“ klicken, stimmen Sie der Verwendung von Cookies auf unserer Website wie in unserer Cookie-Richtlinie beschrieben zu. Sie können Ihre " +
                        "Cookie-Einstellungen jederzeit durch Klick auf „Einstellungen“ ändern.",
                    acceptAllBtn: "Alle akzeptieren",
                    acceptNecessaryBtn: "Alle ablehnen",
                    showPreferencesBtn: "Einstellungen verwalten",
                    footer: "<a href=\"privacy-policy\">Datenschutz</a>" +
                        "<a href=\"impressum\">Impressum</a>"
                },
                preferencesModal: {
                    title: "Präferenzen für die Zustimmung",
                    acceptAllBtn: "Alle akzeptieren",
                    acceptNecessaryBtn: "Alle ablehnen",
                    savePreferencesBtn: "Einstellungen speichern",
                    closeIconLabel: "Modal schließen",
                    serviceCounterLabel: "Dienstleistungen",
                    sections: [
                        {
                            title: "Verwendung von Cookies",
                            description: "Wir verwenden notwendige Cookies, damit unsere Website funktioniert. Mit Ihrer Zustimmung können wir auch nicht notwendige Cookies verwenden, um die Benutzerfreundlichkeit zu verbessern und den Traffic " +
                                "auf unserer Website zu analysieren. Indem Sie auf „Akzeptieren“ klicken, stimmen Sie der Verwendung von Cookies auf unserer Website wie in unserer Cookie-Richtlinie beschrieben zu. Sie können Ihre " +
                                "Cookie-Einstellungen jederzeit durch Klick auf „Einstellungen“ ändern."
                        },
                        {
                            title: "Notwendige Cookies <span class=\"pm__badge\">Immer Aktiviert</span>",
                            description: "- Zum Betrieb der Website erforderlich",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Funktionalitäts Cookies",
                            description: "- Speicherung Ihrer Präferenzen aus früheren Besuchen<br>" +
                                "- Sammeln von Benutzer-Feedback zur Verbesserung unserer Website<br>" +
                                "- Erfassung Ihrer Interessen, um maßgeschneiderte Inhalte und Angebote anzubieten",
                            linkedCategory: "functionality"
                        }
                    ]
                }
            }
        }
    }
}

export default config;
