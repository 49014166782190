<template>
    <Navbar />

	<section id="privacy-policy" class="relative py-32">

		<div class="container relative">

			<div class="title grid grid-cols-1 mb-8 text-center">
				<h4 class="text-4xl font-bold mb-3">Impressum</h4>
				<div class="border"></div>
			</div>

			<div class="grid grid-cols-1">
				<div class="block">

					<h3 class="pb-8">Angaben gemäß § 5 TMG:</h3>

					<h5 class="font-semibold pb-2">Bogenschützen Münster e.V. von 1979</h5>
					<p class="pb-6">
						Werseesch<br />
						48157 Münster
					</p>

					<h4 class="font-semibold pb-2">Vertreten durch:</h4>

					<p class="pb-6">
						Johanna Höffker (1. Vorsitzende)<br />
						Andrea Seidel (2. Vorsitzende)
					</p>

					<h5 class="font-semibold pb-2">Kontakt:</h5>

					<p class="pb-6">
						Telefon: 0160 928 464 12<br />
						E-Mail: <span class="cryptedmail" data-name="info" data-domain="bogenschuetzen-muenster" data-tld="de" onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"></span><br />
						Internet: <a title="Bogenschützen Münster e.V. von 1979" href="https://www.bogenschuetzen-muenster.de">https://www.bogenschuetzen-muenster.de</a>
					</p>

					<h5 class="font-semibold pb-2">Postanschrift:</h5>

					<p class="pb-6">
						Bogenschützen Münster e.V. von 1979<br />
						Postfach 1524<br />
						48004 Münster
					</p>

					<h5 class="font-semibold pb-2">Registereintrag:</h5>

					<p class="pb-6">
						Eintragung im Vereinsregister.<br />
						Registergericht: Amtsgericht Münster<br />
						Registernummer: VR 2342
					</p>

					<h5 class="font-semibold pb-2">Inhaltlicher Verantwortlicher im Sinne des § 18 Abs. 2 MStV:</h5>

					<p class="pb-6">
						Marco Köhler<br />
						Legdenweg 78<br />
						48161 Münster
					</p>

					<h5 class="font-semibold pb-2">Bankverbindung</h5>

					<p class="pb-6">
						Sparkasse Münsterland Ost<br />
						IBAN: DE98400501500003001997<br />
						BIC: WELADED1MST
					</p>

					<h4 class="font-semibold pb-2">Haftungsausschluss:</h4>
					<b class="pb-2">Haftung für Inhalte</b>

					<p class="pb-4">Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
						gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p>

					<p class="pb-4">Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
						Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>

					<b class="pb-2">Haftung für Links</b>

					<p class="pb-4">Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets
						der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
						erkennbar.</p>

					<p class="pb-4">Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>

					<b class="pb-2">Urheberrecht</b>

					<p class="pb-4">Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des
						Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.</p>

					<p class="pb-4">Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
						Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>

				</div>
			</div>

		</div>

	</section>

    <section class="pt-6 bg-dark text-white">
        <Info/>
    </section>

    <Footer/>

    <ScrollToTop/>
</template>

<script setup lang="ts">
import {onMounted} from "vue";

import {useLoaderState} from "@/store/isloading";

import Navbar from "@/components/layout/navbar.vue";
import Info from "@/components/layout/info.vue";
import Footer from "@/components/layout/footer.vue";
import ScrollToTop from "@/components/scroll-to-top.vue";

const isLoading = useLoaderState();
const { changeStateTrue } = isLoading;

onMounted(() => {
	changeStateTrue();

	setTimeout(() => {
		const { changeStateFalse } = isLoading;
		changeStateFalse();
	}, 1000);
})
</script>
