<template>
    <div class="container relative">
        <div class="title grid grid-cols-1 pb-6 text-center" data-aos="fade-down" data-aos-duration="500">
	        <h1 class="font-semibold text-4xl mb-3">Unser Verein</h1>
	        <div class="border"></div>
        </div><!--end grid-->

        <div class="grid grid-cols-1 mt-6 relative" data-aos="fade-up" data-aos-duration="500" data-aos-delay="500">
	        <div class="lg:me-8 order-2 md:order-1">
		        <h3 class="mb-6 text-2xl md:leading-normal leading-normal font-bold">Du möchtest Mitglied bei uns werden?</h3>
		        <p class="text-base my-4">Für Interessierte ohne Erfahrung bieten wir Schnupperkurse an. Nähere Infos findest du unter dem Menüpunkt Schnupperkurse.</p>
		        <p class="text-base my-4">Erfahrene Bogenschützen mit eigener Ausrüstung können gerne nach vorhergehender Anmeldung zu den offiziellen Trainingszeiten zu einem Probetraining vorbeischauen.</p>
		        <p class="text-base my-4">Neue Vereinsmitglieder dürfen das Vereinsgelände bis zur Erlangung der Platzreife durch einen Trainer nur zu den offiziellen Trainingszeiten oder in Begleitung eines erfahrenen Schützen nutzen!</p>

		        <div id="accordion-flush" data-accordion="collapse" data-active-classes="text-gray-500" data-inactive-classes="text-gray-500">
			        <div v-for="(item, index) in faqData" :value="index" :key="index">
				        <h4 :id="'accordion-flush-heading'+item.id" class="text-xl">
					        <button type="button" class="flex text-left items-center justify-between w-full px-2 py-5 font-medium rtl:text-right border-b border-gray-200 dark:border-gray-700 gap-3"
					                :data-accordion-target="'#accordion-flush-body-'+item.id" aria-expanded="false" :aria-controls="'accordion-flush-body-'+item.id">
						        <span>{{item.title}}</span>
						        <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
							        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
						        </svg>
					        </button>
				        </h4>
				        <div :id="'accordion-flush-body-'+item.id" class="hidden text-base p-5" :aria-labelledby="'accordion-flush-heading-'+item.id">
					        <Fees v-if="item.id === 2" />
                       <Training v-if="item.id === 3" />
                       <p class="text-slate-400 dark:text-gray-400" v-html="item.desc"></p>
				        </div>
			        </div>
		        </div>
	        </div>
        </div>
    </div><!--end container-->
</template>

<script setup lang="ts">
import {onMounted, ref} from 'vue';
import {initAccordions} from "flowbite";

import Fees from "@/components/fees.vue";
import Training from "@/components/training.vue";

onMounted(() => {
	initAccordions();
})

const faqData = ref([
	{
		id: 1,
		title: 'Anmeldung',
		desc: 'Wenn du nach einem Schnupperkurs oder Probetraining Mitglied werden möchtest, dann fülle unseren Aufnahmeantrag aus und bringe den Antrag mit zum Training oder schicke den Antrag per Mail an unseren Kassenwart. Den Aufnahmeantrag findest du unter Downloads.'
	},
	{
		id: 2,
		title: 'Jahresbeiträge',
      desc: ''
	},
   {
      id: 3,
      title: 'Trainingszeiten',
      desc: ''
   },
	{
		id: 4,
		title: 'Schnupperkurse',
		desc: 'Für am Verein Interessierte bieten wir Schnupperkurse an. Das Mindestalter beträgt zehn Jahre. Falls du eine körperliche Beeinträchtigung haben solltest, gib dies gerne an, damit sich unsere Trainer optimal darauf einstellen können. Der Schnupperkurs findet nur nach Anmeldung statt für einen Beitrag von 20 € pro Person.' +
				'<br /><br />' +
				'Das Material stellen wir dir zu dem Termin zur Verfügung. Du benötigst im Sommer festes Schuhwerk, da unser Gelände richtig in der Natur liegt, und nicht zu weite Oberbekleidung. Außerdem solltest du entweder eine Regenjacke oder ein Insektenmittel dabeihaben. Wir schießen auch bei Regen, und wenn es trocken ist, haben wir am Flusslauf natürlich eine ganze Menge Insekten herumfliegen.' +
				'<br /><br />' +
				'Bei der ersten Einweisung werden dir die wichtigsten Grundtechniken gezeigt und du kannst auch schon die ersten Schüsse abgeben.' +
				'<br /><br />' +
				'Anmelden kannst du dich über unser Kontaktformular oder direkt bei unserem Sportwart.'
	},
	{
		id: 5,
		title: 'Gastschützen',
		desc: 'Gastschützen dürfen das Gelände nur in Begleitung eines Vereinsmitgliedes nutzen. Das Vereinsmitglied muß die Gastschützen rechtzeitig beim Vorstand anmelden. Das Scheibengeld beträgt für Erwachsene 10 € und für Kinder 5 €.'
	},
	{
		id: 6,
		title: 'Du möchtest deine Mitgliedschaft beenden?',
		desc: 'Deine Kündigung kann formlos über das Kontaktformular oder per E-Mail an den Kassenwart erfolgen. Die Kündigungsfrist beträgt drei Monate zum Kalenderjahresende. Der Austritt ist mit Ablauf des Kalenderjahres rechtskräftig.'
	}
])
</script>
